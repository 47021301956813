import Beneficio from "../pages/Beneficio/Beneficio";
import Beneficiario from "../pages/Beneficiario/Beneficiario";
import Home from "../pages/home/Home";
import NotFound from "../pages/notFound/NotFound";
import { Pastoral } from "../pages/pastoral/Pastoral";
import Atendimento from "../pages/atendimento/Atendimento";
import Relatorio from "../pages/relatorio/Relatorio";
import Escolaridade from "../pages/escolaridade/Escolaridade";
import Nacionalidade from "../pages/nacionalidade/Nacionalidade";
import TipoResidencia from "../pages/tipoResidencia/TipoResidencia";

const Pages = [
  {
    name: "home",
    route: "/",
    component: Home,
  },

  {
    name: "cadastro-beneficiario",
    menuName: "Beneficiário",
    route: "/cadastro-beneficiario",
    component: Beneficiario,
  },
  {
    name: "cadastro-beneficio",
    menuName: "Benefício",
    route: "/cadastro-beneficio",
    component: Beneficio,
  },
  {
    name: "cadastro-pastoral",
    menuName: "Pastoral",
    route: "/cadastro-pastoral",
    component: Pastoral,
  },
  {
    name: "cadastro-atendimento",
    menuName: "Atendimento",
    route: "/cadastro-atendimento",
    component: Atendimento,
  },
  {
    name: "escolaridade",
    menuName: "Escolaridade",
    route: "/escolaridade",
    component: Escolaridade,
  },
  {
    name: "nacionalidade",
    menuName: "Nacionalidade",
    route: "/nacionalidade",
    component: Nacionalidade,
  },
  {
    name: "tipo-residencia",
    menuName: "Tipo Residencia",
    route: "/tipo-residencia",
    component: TipoResidencia,
  },
  {
    name: "relatorios",
    menuName: "Relatorio",
    route: "/relatorios",
    component: Relatorio,
  },
  {
    name: "pageNotFound",
    route: "*",
    component: NotFound,
  },
];

export default Pages;
