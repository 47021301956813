import CustomStore from "devextreme/data/custom_store";
import { Auth } from "../../config/Storage";
import {
  handleParams,
  handleError,
  handleGetSessionStorage,
} from "../../services/helpers";
import qs from "qs";
import api from "../../services/api";
import store from "../../store/Store";

export function saveAtendimento(form, change) {
  let method = form.id ? "PUT" : "POST";
  const data = {
    ...form,
    dataInclusao: form.id ? form.dataInclusao : new Date(),
    status: form.id ? 2 : 1,
  };
  const url = `/Atendimento`;
  return new Promise((resolve, reject) => {
    api({ method, url, data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function handleLoadDependente(idBeneficiario) {
  const auth = JSON.parse(sessionStorage.getItem(Auth));
  const params = {
    idBeneficiario,
    idPessoaEmpresa: auth.empresa,
    skip: 0,
    take: 1000,
  };

  return new Promise((resolve, reject) => {
    api
      .get(
        `BeneficiarioDependente/GetBeneficiarioDependentePorBeneficiario?${qs.stringify(
          params
        )}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function handleLoadBeneficiario() {
  const auth = JSON.parse(sessionStorage.getItem(Auth));
  const params = {
    idPessoaEmpresa: auth.empresa,
    skip: 0,
    take: 1000,
  };

  return new Promise((resolve, reject) => {
    api
      .get(`Beneficiario/GetPorEmpresa?${qs.stringify(params)}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getBeneficiarioAtivo(id) {
  const params = {
    idBeneficiario: id,
  };

  return new Promise((resolve, reject) => {
    api
      .get(`Beneficiario/GetVerificaBeneficiarioAtivo?${qs.stringify(params)}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function handleLoadAtendimento(loadOptions) {
  const auth = JSON.parse(sessionStorage.getItem(Auth));

  const params = {
    idPessoaEmpresa: auth.empresa,
  };
  const newParams = handleParams(params, loadOptions);
  const urlComplete = `/Atendimento/GetPorEmpresa?${qs.stringify(newParams)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

export function handleLoadHistorico(loadOptions, key) {
  const idBeneficiario = store.getState().Atendimento.idBeneficiario;
  const params = {
    idBeneficiario,
  };
  const newParams = handleParams(params, loadOptions);
  const urlComplete = `/AtendimentoBeneficio/GetAtendimentoBenificioPorBeneficiario?${qs.stringify(
    newParams
  )}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

export function handleLoadPastoral() {
  const auth = JSON.parse(sessionStorage.getItem(Auth));
  const params = {
    idPessoaEmpresa: auth.empresa,
    skip: 0,
    take: 1000,
  };
  const urlComplete = `BeneficioPastoral/GetPorEmpresa?${qs.stringify(params)}`;

  return new Promise((resolve, reject) => {
    api
      .get(urlComplete)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function handleRemove(id) {
  return api
    .delete(`/Atendimento/Id/${id}`)
    .then((res) => res)
    .catch((err) => handleError("Erro ao remover", err));
}

function handleLoadPastoralById(loadOptions, url) {
  const { auth } = handleGetSessionStorage();
  const id = typeof loadOptions === "number" ? loadOptions : null;

  const params = {
    idPastoral: id,
    idPessoaEmpresa: auth.empresa,
    skip: 0,
    take: 1000,
  };
  const urlComplete = `/Pastoral/GetPorEmpresa?${qs.stringify(params)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;

      if (id !== null) {
        return data;
      }

      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

export const dataSourceAtendimento = {
  store: new CustomStore({
    key: "id",
    load: handleLoadAtendimento,
    remove: handleRemove,
  }),
};

export const dataSourceHistorico = {
  store: new CustomStore({
    key: "id",
    load: (loadOptions, key) => handleLoadHistorico(loadOptions, key),
  }),
};

export const dataSourcePastoral = {
  store: new CustomStore({
    key: "id",
    byKey: (loadOptions, key) => handleLoadPastoralById(loadOptions, key),
    load: (loadOptions, key) => handleLoadPastoral(loadOptions, key),
  }),
};
