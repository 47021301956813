import React, { useState } from "react";
import { DataGrid } from "devextreme-react";
import {
  Button,
  Column,
  Editing,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Lookup,
} from "devextreme-react/data-grid";

import { Checkbox } from "rsuite";
import { useEffect } from "react";
import { handleGetSessionStorage } from "../../../services/helpers";
import { gridParentesco } from "../BeneficiarioService";

export default function HistoricoAtivacaoGrid({
  form,
  setForm,
  successMessage,
  dataSourceGridHistorico,
  setDataSourceGridHistorico,
  errorGrid,
  setErrorGrid,
}) {
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(15);

  // useEffect(() => {
  //   let { idBeneficiario, listHistoricoGrid } = form;
  //   if (!idBeneficiario) {
  //     setDataSourceGridHistorico([...listHistoricoGrid] || []);
  //   }
  // }, [form.idBeneficiario]);

  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };

  function handleInserting({ data }) {
    const { auth, company } = handleGetSessionStorage();

    const expense = {
      ...data,
      idPessoaEmpresa: auth.empresa,
      id: null,
      status: 1,
      usuario: auth.nomeUsuario,
      idBeneficiario: form.idBeneficiario || 0,
      excluido: false,
    };

    setForm((form) => ({
      ...form,
      listHistoricoGrid: [...form.listHistoricoGrid, expense],
    }));

    setDataSourceGridHistorico(dataSourceGridHistorico);
    setErrorGrid(false);
  }

  function handleNewRow(e) {
    e.data.dataInclusao = new Date().toISOString();
  }

  const checkRender = (event) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 10,
        }}
      >
        <Checkbox style={{ margin: -10 }} checked={event.data.ativo} disabled />
      </div>
    );
  };

  const editCheckRender = (event) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 10,
        }}
      >
        <Checkbox
          style={{ margin: -10 }}
          onCheckboxClick={() => {
            event.setValue(!event.data.ativo, "ativo");
          }}
        />
      </div>
    );
  };

  function handleValidation(e) {
    const { newData, oldData = false } = e;
    newData.ativo = newData.ativo ?? false;
  }

  return (
    <>
      <div style={{ marginTop: 16 }}>
        <h5>Histórico Ativação Beneficiário *</h5>
        <DataGrid
          dataSource={dataSourceGridHistorico}
          remoteOperations={true}
          showBorders={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onRowInserted={(event) => handleInserting(event)}
          onRowValidating={handleValidation}
          //   onEditorPreparing={handleEditorPreparing}
          onInitNewRow={handleNewRow}
        >
          {/* <Pager
            allowedPageSizes={[7, 15, 30]}
            visible={true}
            showPageSizeSelector={true}
            showInfo={true}
          /> */}
          <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
          <Editing mode="row" allowAdding useIcons />
          <FilterRow
            visible={filterOptions.showFilterRow}
            applyFilter={filterOptions.currentFilter}
          />

          <HeaderFilter />

          <Column
            // width="125"
            dataField="motivo"
            caption="Motivo"
            alignment="left"
          >
            <HeaderFilter groupInterval={100} />
          </Column>

          <Column
            dataField="dataInclusao"
            caption="Data"
            dataType="date"
            format="dd/MM/yyyy HH:mm"
            alignment="center"
            width={200}
            allowEditing={true}
          >
            <HeaderFilter groupInterval={100} />
          </Column>

          <Column
            alignment="center"
            dataField="ativo"
            caption="Ativo?"
            cellRender={checkRender}
            editCellRender={editCheckRender}
            width="125"
          >
            <HeaderFilter groupInterval={100} />
          </Column>
        </DataGrid>
        {errorGrid ? (
          <div style={{ color: "#f44336", fontWeight: "bold" }}>
            *É necessário adicionar um histórico para o beneficiario
          </div>
        ) : null}
      </div>
    </>
  );
}
