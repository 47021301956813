import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Col,
  FlexboxGrid,
  SelectPicker as SelectPickerRsuit,
  InputPicker,
} from "rsuite";
import TextField from "../../../components/form/textField/TextField";
import { addIdBeneficiarioAtendimento } from "../../../store/modules/atendimento/action";
import {
  handleLoadBeneficiario,
  handleLoadDependente,
  getBeneficiarioAtivo,
} from "../AtendimentoService";

export default function Beneficiario({
  form,
  setForm,
  errors,
  setErrors,
  setErrorDependente,
  errorDependente,
  errorBeneficiario,
  setErrorBeneficiario,
}) {
  const [listBeneficiario, setListBeneficiario] = useState([]);
  const [listDependente, setListDependente] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    handleLoadBeneficiario().then((res) => setListBeneficiario(res.data));
    handleLoadDependente(form.idBeneficiario).then((res) =>
      setListDependente(res.data)
    );
  }, []);

  useEffect(() => {
    handleLoadBeneficiario().then((res) => setListBeneficiario(res.data));
    handleLoadDependente(form.idBeneficiario).then((res) =>
      setListDependente(res.data)
    );
  }, [form.id]);

  function handleChangeBeneficiario(e) {
    setErrorDependente(null);
    handleLoadDependente(e).then((res) => setListDependente(res.data));
    getBeneficiarioAtivo(e).then((res) =>
      res
        ? setErrorBeneficiario(null)
        : setErrorBeneficiario("O beneficiário não está ativo")
    );
  }
  return (
    <FlexboxGrid align="top" style={{ marginTop: "16px" }}>
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={10}>
        <TextField
          margin="0"
          width="100%"
          name="idBeneficiario"
          label="Beneficiário*"
          accepter={InputPicker}
          valueKey="id"
          labelKey="nome"
          data={listBeneficiario}
          onChange={handleChangeBeneficiario}
          onClean={() =>
            setForm({
              ...form,
              idBeneficiarioDependente: null,
              idBeneficiario: null,
            })
          }
          error={errors.idBeneficiario || errorBeneficiario}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={14}>
        <TextField
          margin="0"
          width="100%"
          name="idBeneficiarioDependente"
          label="Dependente"
          accepter={InputPicker}
          valueKey="id"
          labelKey="nome"
          disabled={!form.idBeneficiario}
          data={listDependente}
          onSelect={(e, item) => {
            if (!item.autorizado) {
              setErrorDependente("O dependente não está autorizado");
            } else {
              setErrorDependente(null);
            }
          }}
          onClean={() => setErrorDependente(null)}
          error={errorDependente}
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
