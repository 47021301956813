import React from "react";
import { Form, FormGroup } from "rsuite";
import { useToasts } from "react-toast-notifications";

import EscolaridadeGrid from "./EscolaridadeGrid";

export default function Escolaridade() {
  const { addToast } = useToasts();

  function successMessage(message) {
    addToast(message, {
      appearance: "success",
      autoDismiss: true,
    });
  }

  function warningMessage(message) {
    addToast(message, {
      appearance: "warning",
      autoDismiss: true,
    });
  }

  return (
    <div className="pageContainer">
      <div className="pageFlex">
        <div className="pageContent withoutSearch w650">
          <h4>Escolaridade</h4>
          <Form className="pageForm">
            <FormGroup
              className="pageFormContainer"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <EscolaridadeGrid
                successMessage={successMessage}
                warningMessage={warningMessage}
              />
            </FormGroup>
          </Form>
        </div>
      </div>
    </div>
  );
}
