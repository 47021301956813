import React, { useEffect, useState } from "react";
import { ControlLabel, FormControl, FormGroup } from "rsuite";
import SelectPickerModal from "./SelectPickerModal";

export default function SelectPicker({
  columns,
  dataSource,
  disabled = false,
  editValue = null,
  formData,
  label,
  displayExpr,
  onChange,
  placeholder = "",
  valueExpr = "",
  error = "",
  value,
  ...rest
}) {
  const [description, setDescription] = useState("");
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setDescription(editValue);
  }, [editValue]);

  function checkIfHasValue() {
    if (!value) return;
  }

  function toggleModal(event) {
    setToggle(!toggle);
    if (event) {
      setDescription(event[displayExpr]);
      onChange(event);
    }
  }
  return (
    <>
      <SelectPickerModal
        dataSource={dataSource}
        colums={columns}
        title={label}
        toggle={toggle && !disabled}
        toggleModal={toggleModal}
        {...rest}
      />
      <FormGroup onClick={() => toggleModal()}>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          name={valueExpr}
          errorMessage={error}
          placeholder={placeholder}
          value={description}
          readOnly={true}
          onChange={checkIfHasValue}
          autoComplete="off"
          style={{ width: "100%" }}
        />
      </FormGroup>
    </>
  );
}
