import PropTypes from "prop-types";
import React from "react";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.br";

import "../Form.css";
import { FormGroup, ControlLabel, FormControl, Tooltip, Whisper } from "rsuite";
import removerAcentosEspacos from "../../../services/helpers";

const InputMask = ({ onChange, ...rest }) => {
  return (
    <Cleave
      {...rest}
      className="formInput rs-input"
      onChange={(event) => {
        onChange(event.target.value);
      }}
    />
  );
};

const MaskInput = ({
  autoFocus,
  label,
  disabled,
  form,
  value,
  setForm,
  invalid,
  error,
  state,
  onBlur,
  onChange,
  stateBlur,
  placeholder,
  mask,
  width,
  margin,
}) => {
  return (
    <div>
      <FormGroup
      // className="rs-form-control-wrapper"
      /* style={{ width: width || 240 }} */
      >
        <ControlLabel>{label}</ControlLabel>
        <Whisper
          open={error || invalid ? true : false}
          trigger="none"
          placement={"bottomStart"}
          speaker={<Tooltip className="tooltip">{error}</Tooltip>}
        >
          <FormControl
            autoFocus={autoFocus}
            className="formInput"
            style={{ width: "100%" }}
            name={removerAcentosEspacos(label)}
            accepter={InputMask}
            options={mask}
            disabled={disabled}
            value={value}
            onBlur={() => {
              if (setForm) {
                setForm({
                  ...form,
                  [stateBlur]: true,
                });
              }
              if (onBlur) onBlur(value);
            }}
            onChange={(value) => {
              if (setForm) {
                setForm({
                  ...form,
                  [state]: value,
                });
              }

              if (onChange) onChange(value);
            }}
            placeholder={placeholder}
          />
        </Whisper>
      </FormGroup>
    </div>
  );
};

MaskInput.propTypes = {
  disabled: PropTypes.any,
  error: PropTypes.any,
  form: PropTypes.any,
  invalid: PropTypes.any,
  label: PropTypes.any,
  onBlur: PropTypes.any,
  onChange: PropTypes.any,
  placeholder: PropTypes.any,
  setForm: PropTypes.func,
  state: PropTypes.any,
  mask: PropTypes.shape({
    creditCard: PropTypes.bool,
    phone: PropTypes.bool,
    phoneRegionCode: PropTypes.string,
    date: PropTypes.bool,
    delimiter: PropTypes.string,
    datePattern: PropTypes.array,
    time: PropTypes.bool,
    timePattern: PropTypes.array,
    numeral: PropTypes.bool,
    blocks: PropTypes.array,
    uppercase: PropTypes.bool,
    delimiters: PropTypes.array,
    prefix: PropTypes.string,
    numericOnly: PropTypes.bool,
    numeralThousandsGroupStyle: PropTypes.string,
  }),
};

export default MaskInput;
