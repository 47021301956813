import { Auth } from "../../config/Storage";

export default function handleSystemVariable(
  filter,
  setFilter,
  inputs,
  setInputs
) {
  const auth = JSON.parse(sessionStorage.getItem(Auth));
  inputs.map((item) => {
    if (item.variavelSistema) {
      switch (item.conteudo) {
        case "EMPRESA":
          setFilter((oldFilter) => ({ ...oldFilter, EMPRESA: auth?.empresa }));
          item.value = auth?.empresa;
          setInputs([...inputs]);
          break;

        case "NOMEUSUARIO":
          setFilter((oldFilter) => ({
            ...oldFilter,
            NOMEUSUARIO: auth?.nomeUsuario,
          }));
          item.value = auth?.nomeUsuario;
          setInputs([...inputs]);
          break;
        case "IDREDECREDENCIADA":
          setFilter((oldFilter) => ({
            ...oldFilter,
            IDREDECREDENCIADA: auth?.idRedeCredenciada,
          }));
          item.value = auth?.idRedeCredenciada;
          setInputs([...inputs]);
        case "IDCLIENTE":
          setFilter((oldFilter) => ({
            ...oldFilter,
            IDCLIENTE: auth?.idCliente,
          }));
          item.value = auth?.idCliente;
          setInputs([...inputs]);
        default:
          break;
      }
      return item;
    }
  });
}
