import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import logo from "../../assets/images/logo.png";
import { Auth } from "../../config/Storage";
import "./Footer.css";

export default function Footer() {
  const user = JSON.parse(sessionStorage.getItem(Auth))?.nomeUsuario;
  const { nomeParoquia } = useSelector((state) => state.Company);
  const date = useCallback(() => {
    const dayList = [
      "domingo",
      "segunda-feira",
      "terça-feira",
      "quarta-feira",
      "quinta-feira",
      "sexta-feira",
    ];
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date();
    const dateFull = date.toLocaleDateString("pt-br", options);
    const dayWeek = date.getDay();
    const day = dayList[dayWeek];
    return `${day}, ${dateFull}`;
  }, []);

  return (
    <div className="footerContainer">
      <a
        href="https://www.orgsystem.com.br/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="footerLogo"
          height="24"
          width="94.06"
          src={logo}
          alt="logo"
        />
      </a>
      <div className="footerItem">
        {`Paróquia - `}
        <b>{nomeParoquia}</b>
      </div>
      <div className="footerItem">
        {`Usuário logado - `}
        <b>{user}</b>
      </div>

      <div className="footerText text-muted" title={date()}>
        {date()}
      </div>
    </div>
  );
}
