export default function Escolaridade(state = {}, action) {
  switch (action.type) {
    case "SELECT_ITEM": {
      return {
        selected: action.selected,
      };
    }
    default:
      return state;
  }
}
