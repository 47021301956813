import React from "react";
import { Col, FlexboxGrid } from "rsuite";
import TextField from "../../../components/form/textField/TextField";
import DateInput from "../../../components/form/dateInput/DateInput";
import { Company } from "../../../config/Storage";

export default function Paroquia({ form, setForm }) {
  const { nomeParoquia } = JSON.parse(sessionStorage.getItem(Company));
  return (
    <FlexboxGrid
      align="top"
      style={{ marginTop: "16px", justifyContent: "space-between" }}
    >
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
        <TextField
          width="100%"
          label="Paróquia"
          name="paroquia"
          disabled
          value={nomeParoquia}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={5}>
        <DateInput
          style={{ margin: 0 }}
          label="Data/Hora Atendimento"
          form={form}
          value={form.dataInclusao}
          setForm={setForm}
          state="dataInclusao"
          width="100%"
          disabled
          margin="0"
          format="DD/MM/YYYY HH:mm:ss"
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
