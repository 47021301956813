import React from "react";
import { Col, FlexboxGrid, DatePicker, InputPicker } from "rsuite";
import TextField from "../../../components/form/textField/TextField";
import { isBefore } from "date-fns";
import { mes as listMes } from "../../../services/helpers";
export default function MesAnoData({ form, errors, setForm }) {
  return (
    <FlexboxGrid align="top" style={{ margin: "16px 0 32px 0" }}>
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={3}>
        <TextField
          margin="0"
          width="100%"
          name="mes"
          label="Mês*"
          accepter={InputPicker}
          data={listMes}
          labelKey="name"
          valueKey="value"
          error={errors?.mes}
          onChange={(e) => setForm({ ...form, mes: e, dataRetorno: null })}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={3}>
        <TextField
          width="100%"
          label="Ano*"
          name="ano"
          type="number"
          error={errors?.ano}
          onChange={(e) => setForm({ ...form, dataRetorno: null, ano: e })}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
        <TextField
          name="dataRetorno"
          label="Data Retorno"
          placeholder="Data Retorno"
          format="DD/MM/YYYY"
          disabled={!form.mes || !form.ano}
          accepter={DatePicker}
          width={200}
          oneTap
          disabledDate={(date) =>
            isBefore(date, new Date(`${form.mes}/01/${form.ano}`))
          }
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
