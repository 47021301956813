export default function Beneficio(state = {}, action) {
  switch (action.type) {
    case 'ADD_ID_BENEFICIO': {
      return {
        ...state,
        idBeneficio: action.idBeneficio,
      };
    }
    default:
      return state;
  }
}
