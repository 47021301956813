import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { Form, FormGroup, Nav } from "rsuite";
import FilterModal from "../../components/filter/FilterModal";
import "../Page.css";
import AtendimentoFilter from "./AtendimentoFilter";
import AtendimentoForm from "./AtendimentoForm";
import HistoricoGrid from "./Grids/HistoricoGrid";
import { handleGetSessionStorage } from "../../services/helpers";
import swal from "sweetalert";

export default function Atendimento() {
  const { auth, company } = handleGetSessionStorage();
  const initialForm = {
    id: null,
    idPessoaEmpresa: auth.empresa,
    idParoquia: company,
    idBeneficiario: null,
    idBeneficiarioDependente: null,
    ano: "",
    mes: "",
    dataRetorno: null,
    dataInclusao: null,
    usuario: auth.nomeUsuario,
    excluido: false,
    status: 1,
    listAtendimentoBeneficio: [],
  };
  const [dataSourceGrid, setDataSourceGrid] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [form, setForm] = useState(initialForm);
  const [active, setActive] = useState("atendimento");
  const [errors, setErrors] = useState({});
  const [change, setChange] = useState(false);
  const [list, setList] = useState([]);
  const [errorGrid, setErrorGrid] = useState(false);
  const [errorDependente, setErrorDependente] = useState(false);
  const [errorBeneficiario, setErrorBeneficiario] = useState(false);

  function successMessage(message) {
    swal({
      title: message,
      icon: "success",
    });
  }

  function errorMessage(message) {
    swal({
      title: message,
      icon: "error",
    });
  }

  const CustomNav = ({ active, onSelect, id, ...props }) => {
    return (
      <Nav {...props} activeKey={active} onSelect={onSelect}>
        <Nav.Item eventKey="atendimento">Atendimento</Nav.Item>
        <Nav.Item eventKey="historico" disabled={id ? false : true}>
          Histórico
        </Nav.Item>
      </Nav>
    );
  };

  return (
    <div className="pageContainer">
      <div className="pageFlex">
        <div className="pageContent withoutSearch">
          <h3>Atendimento</h3>
          <CustomNav
            appearance="tabs"
            active={active}
            onSelect={(activeKey) => setActive(activeKey)}
            id={form.id}
            style={{ marginTop: 15 }}
          />
          {active === "atendimento" ? (
            <Form className="pageForm">
              <FormGroup
                className="pageFormContainer"
                style={{ display: "block" }}
              >
                <AtendimentoForm
                  form={form}
                  setForm={setForm}
                  errorMessage={errorMessage}
                  successMessage={successMessage}
                  errorMessage={errorMessage}
                  initialForm={initialForm}
                  dataSourceGrid={dataSourceGrid}
                  setDataSourceGrid={setDataSourceGrid}
                  errors={errors}
                  setErrors={setErrors}
                  change={change}
                  setChange={setChange}
                  list={list}
                  setList={setList}
                  errorGrid={errorGrid}
                  setErrorGrid={setErrorGrid}
                  errorDependente={errorDependente}
                  setErrorDependente={setErrorDependente}
                  errorBeneficiario={errorBeneficiario}
                  setErrorBeneficiario={setErrorBeneficiario}
                />
              </FormGroup>
            </Form>
          ) : (
            <HistoricoGrid />
          )}
        </div>
        <FilterModal toggle={toggle} setToggle={setToggle} full>
          <AtendimentoFilter
            form={form}
            setForm={setForm}
            setToggle={setToggle}
            errorMessage={errorMessage}
            successMessage={successMessage}
            initialForm={initialForm}
            setDataSourceGrid={setDataSourceGrid}
            setErrorBeneficiario={setErrorBeneficiario}
            setErrorDependente={setErrorDependente}
            setErrors={setErrors}
            setErrorGrid={setErrorGrid}
          />
        </FilterModal>
      </div>
    </div>
  );
}
