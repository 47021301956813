import React, { useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import { Modal } from "rsuite";

const SelectPickerModal = (
  { toggle, toggleModal, title, modalSize, dataSource, colums },
  ...rest
) => {
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  const handleOption = (event) => {
    toggleModal(event.data);
  };

  function handleOptionChange(event) {
    if (event.fullName === "paging.pageSize" && event.value !== pageSize) {
      setPageSize(event.value);
      setPageIndex(0);
    }

    setPageIndex(null);
  }

  return (
    <Modal
      show={toggle}
      onHide={toggleModal}
      size={modalSize || "md"}
      {...rest}
    >
      <Modal.Header>
        <Modal.Title style={{ fontSize: "17pt" }}>
          Selecione {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataGrid
          dataSource={dataSource}
          remoteOperations={true}
          showBorders={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onRowClick={handleOption}
          onOptionChanged={handleOptionChange}
        >
          <Pager visible={true} showPageSizeSelector={true} showInfo={true} />
          <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />

          <FilterRow visible={true} />
          <HeaderFilter />

          {colums.map((item, index) => (
            <Column
              alignment={item.alignment}
              caption={item.caption}
              dataField={item.dataField}
              filterOperations={false}
              selectedFilterOperation="="
              style={{ cursor: "pointer" }}
              cssClass="columnModal"
              width={item.width}
              key={index}
            ></Column>
          ))}
        </DataGrid>
      </Modal.Body>
    </Modal>
  );
};

export default SelectPickerModal;
