import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Button, FlexboxGrid, Form, Modal, Schema } from "rsuite";
import TextField from "../../components/form/textField/TextField";
import SelectDataPicker from "../../components/selectDataPicker/SelectDataPicker";
import { Company } from "../../config/Storage";
import { handleSetSessionStorage, useDebounce } from "../../services/helpers";
import { addCompany, toggleModal } from "../../store/modules/company/action";
import {
  dioceseStore,
  getParoquiaStore,
  getRegiaoStore,
  handleLoadDiocese,
  handleLoadParoquia,
  handleLoadRegiao,
} from "./ModalCompanyService";

export default function ModalCompany(props) {
  const companyStorage = sessionStorage.getItem(Company)
    ? {
        ...JSON.parse(sessionStorage.getItem(Company)),
        idParoquia: JSON.parse(sessionStorage.getItem(Company)).codigoParoquia,
      }
    : {
        nomeRegiao: "",
        nomeParoquia: "",
        nomeDiocese: "",
        idDiocese: "",
        idParoquia: "",
        idRegiao: "",
      };

  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => ({
    isOpen: state.Company.isOpenModal,
  }));
  const [form, setForm] = useState(companyStorage);
  const { NumberType } = Schema.Types;
  const model = Schema.Model({
    idDiocese: NumberType(),
    idParoquia: NumberType(),
    idRegiao: NumberType(),
  });

  useEffect(() => {
    dispatch(
      addCompany({
        ...form,
        codigoParoquia: form.idParoquia,
      })
    );
  }, [form, dispatch]);

  function checkToggle() {
    if (!form.idParoquia) {
      return errorMessage("Selecione a Paróquia, por favor");
    }

    dispatch(toggleModal(false));
  }

  function errorMessage(message) {
    addToast(message, {
      appearance: "error",
      autoDismiss: true,
    });
  }

  function handleConfirm() {
    if (!form.idParoquia) return checkToggle();
    dispatch(toggleModal(false));
    const companyString = JSON.stringify({
      ...form,
      codigoParoquia: form.idParoquia,
    });

    handleSetSessionStorage("company", companyString);
    dispatch(
      addCompany({
        ...form,
        codigoParoquia: form.idParoquia,
      })
    );
  }

  function handleFormChange(value) {
    setForm({ ...form, ...value });
  }

  function handleDiocese(event) {
    if (!event?.id) return;
    setForm((form) => ({
      ...form,
      idDiocese: event.id,
      nomeDiocese: event.nome,
    }));
  }

  async function handleDioceseValue(value) {
    if (value?.length < 1) return value;
    const params = {
      filter: ["idDiocese", "=", value],
      skip: 0,
      take: 1,
    };
    const { data } = await handleLoadDiocese(params);
    if (data?.length > 0) {
      handleDiocese(data[0]);
    }
    return value;
  }

  function handleRegiao(event) {
    if (!event?.id) return;
    setForm((form) => ({
      ...form,
      idRegiao: event.id,
      nomeRegiao: event.nome,
    }));
  }

  async function handleRegiaoValue(value) {
    if (value?.length < 1) return value;
    const params = {
      filter: ["idRegiao", "=", value],
      skip: 0,
      take: 1,
    };
    const { data } = await handleLoadRegiao(params, form.idDiocese);
    if (data?.length > 0) {
      handleRegiao(data[0]);
    }
    return value;
  }

  function handleParoquia(event) {
    if (!event?.id) return;
    setForm((form) => ({
      ...form,
      idParoquia: event.id,
      nomeParoquia: event.nome,
    }));
  }

  async function handleParoquiaValue(value) {
    if (value?.length < 1) return value;
    const params = {
      filter: ["idParoquia", "=", value],
      skip: 0,
      take: 1,
    };
    const { data } = await handleLoadParoquia(params, form.idRegiao);
    if (data?.length > 0) {
      handleParoquia(data[0]);
    }
    return value;
  }

  return (
    <Modal show={isOpen} onHide={checkToggle}>
      <Modal.Header>
        <Modal.Title>Selecione a Diocese</Modal.Title>
      </Modal.Header>
      <Modal.Body className="marginBottom">
        <Form formValue={form} model={model} onChange={handleFormChange}>
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={6} style={{ paddingRight: 8 }}>
              <TextField
                margin="0"
                width="100%"
                name="idDiocese"
                label="Código Diocese"
                onChange={useDebounce(handleDioceseValue, 500)}
              />
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={18} style={{ paddingLeft: 8 }}>
              <SelectDataPicker
                dataSource={dioceseStore}
                label="Diocese*"
                displayExpr="nome"
                value={form.idDiocese}
                editValue={form.nomeDiocese}
                valueExpr="nomeDiocese"
                colums={[
                  { dataField: "id", caption: "Código" },
                  { dataField: "nome", caption: "Nome Diocese" },
                ]}
                onChange={(event) => {
                  if (event?.id) {
                    setForm({
                      ...form,
                      nomeDiocese: event?.nome,
                      idDiocese: event?.id,
                    });
                  }
                }}
                width={"100%"}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>

          <FlexboxGrid style={{ marginTop: "16px" }}>
            <FlexboxGrid.Item colspan={6} style={{ paddingRight: 8 }}>
              <TextField
                margin="0"
                width="100%"
                name="idRegiao"
                label="Código Região"
                onChange={useDebounce(handleRegiaoValue, 500)}
                disabled={!form.idDiocese}
              />
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={18} style={{ paddingLeft: 8 }}>
              <SelectDataPicker
                dataSource={getRegiaoStore(form)}
                label="Região*"
                displayExpr="nomeRegiao"
                value={form.idRegiao}
                editValue={form.nomeRegiao}
                colums={[
                  { dataField: "id", caption: "Código" },
                  { dataField: "nome", caption: "Nome Região" },
                ]}
                disabled={!form.idDiocese}
                onChange={(event) => {
                  if (event?.id) {
                    setForm({
                      ...form,
                      nomeRegiao: event?.nome,
                      idRegiao: event?.id,
                    });
                  }
                }}
                width={"100%"}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>

          <FlexboxGrid style={{ marginTop: "16px" }}>
            <FlexboxGrid.Item colspan={6} style={{ paddingRight: 8 }}>
              <TextField
                margin="0"
                width="100%"
                name="idParoquia"
                label="Código Paróquia"
                onChange={useDebounce(handleParoquiaValue, 500)}
                disabled={!form.idRegiao}
              />
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={18} style={{ paddingLeft: 8 }}>
              <SelectDataPicker
                dataSource={getParoquiaStore(form)}
                label="Paróquia*"
                displayExpr="nomeParoquia"
                value={form.idParoquia}
                editValue={form.nomeParoquia}
                colums={[
                  { dataField: "id", caption: "Código" },
                  { dataField: "nome", caption: "Nome Paróquia" },
                ]}
                disabled={!form.idRegiao}
                onChange={(event) => {
                  if (event?.id) {
                    setForm({
                      ...form,
                      nomeParoquia: event?.nome,
                      idParoquia: event?.id,
                    });
                  }
                }}
                width={"100%"}
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          color="green"
          disabled={!form.idDiocese || !form.idRegiao || !form.idParoquia}
          onClick={handleConfirm}
          block
          style={{ marginTop: "16px" }}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
