import PropTypes from "prop-types";
import React from "react";

import "../Form.css";
import { FormGroup, ControlLabel, Whisper, Tooltip } from "rsuite";

const DateInputEditable = ({
  id,
  label,
  value,
  margin,
  setForm,
  invalid,
  error,
  state,
  disabled = false,
  onChange,
  month,
  maxDate,
  width,
}) => {
  function handleValue(month, value) {
    if (month) return value;
    if (value == "Invalid Date") return "";
    return value && new Date(value) !== "Invalid Date"
      ? new Date(value)?.toISOString().slice(0, 10)
      : "";
  }

  return (
    <div style={{ margin: margin ?? 8 }}>
      <FormGroup className="rs-form-control-wrapper">
        <ControlLabel>{label}</ControlLabel>
        <Whisper
          open={error || invalid ? true : false}
          trigger="none"
          placement={"bottomStart"}
          speaker={<Tooltip className="tooltip">{error}</Tooltip>}
        >
          <input
            id={id}
            className="rs-input"
            type={month ? "month" : "date"}
            onChange={(e) => {
              if (e.target.value) {
                if (setForm) {
                  setForm((form) => ({
                    ...form,
                    [state]: new Date(`${e.target.value}T03:00:00Z`),
                  }));
                }
                if (onChange) {
                  if (month) return onChange(e.target.value);
                  onChange({
                    iso: `${e.target.value}T03:00:00Z`,
                    date: new Date(`${e.target.value}T03:00:00Z`),
                  });
                }
              }
            }}
            value={handleValue(month, value)}
            max={maxDate ? new Date(maxDate).toISOString().slice(0, 10) : null}
            style={{ width: width ?? "100%", maxHeight: 36 }}
            disabled={disabled}
          />
        </Whisper>
      </FormGroup>
    </div>
  );
};

DateInputEditable.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  invalid: PropTypes.any,
  label: PropTypes.string,
  margin: PropTypes.any,
  maxDate: PropTypes.any,
  setForm: PropTypes.func,
  state: PropTypes.string,
  width: PropTypes.string,
  value: PropTypes.any,
};

export default DateInputEditable;
