import { useRef, useState } from "react";
import { Button, FlexboxGrid, Form, FormGroup, Schema } from "rsuite";
import { handleCreateBeneficario, handleGetCpf } from "./BeneficiarioService";
import EnderecoPessoa from "./form/EnderecoPessoa";
import InfoPessoas from "./form/InfoPessoas";
import DependentesGrid from "./grid/DependentesGrid";
import HistoricoAtivacaoGrid from "./grid/HistoricoAtivacaoGrid";

export default function BeneficiarioForm({
  form,
  setForm,
  successMessage,
  initialForm,
  dataSourceGridDependentes,
  setDataSourceGridDependentes,
  dataSourceGridHistorico,
  errorGrid,
  setErrorGrid,
  setDataSourceGridHistorico,
  change,
  setChange,
  cpfCadastrado,
  setCpfCadastrado,
  errors,
  setErrors,
}) {
  let formValid = useRef();

  const invalidCep = form.cep?.length !== 14;

  function handleFormChange(value) {
    setForm({ ...form, ...value });
    setChange(true);
  }

  async function handleSubmit(e) {
    const response =
      form.cpf !== cpfCadastrado && (await handleGetCpf(form.cpf));
    if (response) {
      return setErrors({ ...errors, cpf: "CPF já existe" });
    }
    console.log(errors, form);
    if (formValid.check()) {
      if (!dataSourceGridHistorico.length) {
        return setErrorGrid(true);
      }
      e.preventDefault();
      await handleCreateBeneficario(form, change).then((res) => {
        setChange(false);
        if (!res.id) return;
        let list = res.listBeneficiarioDependentes.filter(
          (item) => item.status !== 3
        );
        setForm({
          ...form,
          idBeneficiario: res.id,
          dataInclusao: res.dataInclusao,
          listDependenteGrid: list,
        });
        setDataSourceGridDependentes([...list]);
      });
    }
  }

  const { StringType, NumberType, DateType, BooleanType } = Schema.Types;

  const model = Schema.Model({
    cpf: StringType().isRequired("CPF é requirido"),
    cep: StringType().isRequired("CEP é requirido"),
    idEstadoCivil: NumberType().isRequired("Estado Civil é requirido"),
    idLogradouro: NumberType().isRequired("Logradouro é requirido"),
    idTipoResidencia: NumberType().isRequired("Tipo Residência é requirido"),
    idCidade: NumberType().isRequired("Cidade é requirido"),
    idNacionalidade: NumberType().isRequired("Nacionalidade é requerido"),
    idEscolaridade: NumberType().isRequired("Escolaridade é requerido"),
    rendaFamiliar: NumberType().isRequired("Renda Familiar é obrigatório"),
    idUf: NumberType().isRequired("UF é requirido"),
    nome: StringType().isRequired("Nome é requirido"),
    endereco: StringType().isRequired("Endereco é requirido"),
    numero: StringType().isRequired("Numero é requirido"),
    bairro: StringType().isRequired("Bairro é requirido"),
    sexo: StringType().isRequired("Sexo é requirido"),
    nascimento: DateType().isRequired("Nascimento é requirido"),
  });

  return (
    <Form
      formValue={form}
      onChange={handleFormChange}
      formError={errors}
      onCheck={setErrors}
      model={model}
      onSubmit={(e) => handleSubmit(e)}
      ref={(ref) => (formValid = ref)}
      fluid
      style={{ width: "100%" }}
    >
      <FormGroup className="pageFormContainer" style={{ display: "block" }}>
        <InfoPessoas
          errors={errors}
          setErrors={setErrors}
          form={form}
          setForm={setForm}
        />
        <EnderecoPessoa
          errors={errors}
          setErrors={setErrors}
          form={form}
          setForm={setForm}
          invalidCep={invalidCep}
        />
        <DependentesGrid
          form={form}
          setForm={setForm}
          successMessage={successMessage}
          dataSourceGridDependentes={dataSourceGridDependentes}
          setDataSourceGridDependentes={setDataSourceGridDependentes}
        />
        <HistoricoAtivacaoGrid
          form={form}
          setForm={setForm}
          successMessage={successMessage}
          dataSourceGridHistorico={dataSourceGridHistorico}
          setDataSourceGridHistorico={setDataSourceGridHistorico}
          errorGrid={errorGrid}
          setErrorGrid={setErrorGrid}
        />
        <FlexboxGrid
          className="marginTop"
          justify="end"
          style={{ marginTop: "32px" }}
        >
          <>
            {form.idBeneficiario ? (
              <>
                <Button
                  type="button"
                  appearance="ghost"
                  color="blue"
                  onClick={() => {
                    setErrors({});
                    setErrorGrid(false);
                    setForm(initialForm);
                    setCpfCadastrado("");
                    setDataSourceGridDependentes([]);
                    setDataSourceGridHistorico([]);
                  }}
                  style={{ marginRight: "16px" }}
                  setChange={false}
                >
                  Incluir Novo
                </Button>

                <Button
                  type="button"
                  appearance="primary"
                  color="green"
                  onClick={(e) => handleSubmit(e)}
                >
                  Alterar
                </Button>
              </>
            ) : (
              <Button
                type="button"
                appearance="primary"
                color="green"
                onClick={(e) => handleSubmit(e)}
              >
                Salvar
              </Button>
            )}
          </>
        </FlexboxGrid>
      </FormGroup>
    </Form>
  );
}
