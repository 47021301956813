/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'rsuite';
import { FiSearch } from 'react-icons/fi';

import './Filter.css';

const FilterModal = ({
  children,
  modalSize,
  toggle,
  setToggle,
  full,
  ...rest
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (toggle) {
      setShow(!show);
      setToggle(false);
    }
  }, [toggle]);

  return (
    <div className="filterMain">
      <Button
        appearance="ghost"
        active
        onClick={() => {
          setShow(true);
        }}
        className="filterButton"
      >
        <FiSearch size="25" /> Pesquisar
      </Button>
      {/* size ["xs", "sm","md" ,"lg"] */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size={modalSize || 'md'}
        full={full}
        {...rest}
      >
        <Modal.Header>
          <Modal.Title style={{ fontSize: '17pt' }}>Pesquisa</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </div>
  );
};

export default FilterModal;
