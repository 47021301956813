import CustomStore from "devextreme/data/custom_store";
import qs from "qs";
import swal from "sweetalert";
import api from "../../services/api";
import {
  handleError,
  handleGetSessionStorage,
  handleParams,
} from "../../services/helpers";
import { postAndPut } from "./object";

export function handleLoadCity(loadOptions, uf) {
  const params = {
    sigla: uf,
  };
  const newParams = handleParams(params, loadOptions);
  const urlComplete = `/Cidade/GetNomeCidadeUf?${qs.stringify(newParams)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

export function handleLoadLogradouro(loadOptions, logradouro) {
  const params = {
    logradouro,
  };
  const newParams = handleParams(params, loadOptions);
  const urlComplete = `/Logradouro/GetLogradouro?${qs.stringify(newParams)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

export function handleLoadUf(loadOptions) {
  const params = {};
  const newParams = handleParams(params, loadOptions);
  const urlComplete = `/Uf/GetUfs?${qs.stringify(newParams)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

export const gridDataSourceUf = {
  store: new CustomStore({
    key: "id",
    load: (loadOptions) => {
      return handleLoadUf(loadOptions);
    },
  }),
};

export function handleLoadNacionalidade(loadOptions) {
  const { auth } = handleGetSessionStorage();
  const params = {
    idPessoaEmpresa: auth.empresa,
  };
  const newParams = handleParams(params, loadOptions);
  const urlComplete = `/Nacionalidade/GetPorEmpresa?${qs.stringify(newParams)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

export const gridDataSourceNacionalidade = {
  store: new CustomStore({
    key: "id",
    load: (loadOptions) => {
      return handleLoadNacionalidade(loadOptions);
    },
  }),
};

export function handleLoadEscolaridade(loadOptions) {
  const { auth } = handleGetSessionStorage();
  const params = {
    idPessoaEmpresa: auth.empresa,
  };
  const newParams = handleParams(params, loadOptions);
  const urlComplete = `/Escolaridade/GetPorEmpresa?${qs.stringify(newParams)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

export const gridDataSourceEscolaridade = {
  store: new CustomStore({
    key: "id",
    load: (loadOptions) => {
      return handleLoadEscolaridade(loadOptions);
    },
  }),
};

export function handleLoadResidencia(loadOptions) {
  const { auth } = handleGetSessionStorage();
  const params = {
    idPessoaEmpresa: auth.empresa,
  };
  const newParams = handleParams(params, loadOptions);
  const urlComplete = `/TipoResidencia/GetPorEmpresa?${qs.stringify(
    newParams
  )}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

export const gridDataSourceResidencia = {
  store: new CustomStore({
    key: "id",
    load: (loadOptions) => {
      return handleLoadResidencia(loadOptions);
    },
  }),
};

export function handleLoadAccountParentesco(loadOptions) {
  const params = {};
  const newParams = handleParams(params, loadOptions);
  const urlComplete = `/Parentesco/GetParentesco?${qs.stringify(newParams)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

export function handleLoadAccountParentescoById(loadOptions) {
  const id = typeof loadOptions === "number" ? loadOptions : null;
  const params = {
    id: id,
    skip: 0,
    take: 20,
  };

  const urlComplete = `/Parentesco/GetParentesco?${qs.stringify(params)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;

      if (id !== null) {
        return data;
      }

      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

export const gridParentesco = {
  store: new CustomStore({
    key: "id",
    load: handleLoadAccountParentesco,
    byKey: handleLoadAccountParentescoById,
  }),
  pageSize: 10,
  // paginate: true
};

export function handleLoadEstadoCivil() {
  const params = {
    skip: 0,
    take: 20,
  };
  const urlComplete = `/EstadoCivil/GetEstadosCivils?${qs.stringify(params)}`;

  return api
    .get(urlComplete)
    .then((res) => res.data)
    .catch((err) => handleError("Erro ao carregar", err));
}

export function handleGetCpf(e) {
  if (e.length === 14) {
    const params = {
      cpf: e,
    };
    const urlComplete = `/Beneficiario/VerificaCpf?${qs.stringify(params)}`;

    return api
      .get(urlComplete)
      .then((res) => res.data)
      .catch((err) => handleError("Erro ao carregar", err));
  }
}

//----------------------------------------------------------------------------------------
//POST
export function handleCreateBeneficario(form, change) {
  const method = form.idBeneficiario ? "put" : "post";
  const feedback = form.idBeneficiario ? "Alteração feita" : "Cadastro feito";
  const data = postAndPut(form, change, method);
  const url = "Beneficiario";
  return api({ method, url, data })
    .then((res) => {
      swal({
        title: feedback,
        icon: "success",
      });
      return res.data;
    })
    .catch((err) => {
      swal({
        title: err.message,
        icon: "error",
      });
    });
}

/**---------------------------------------------------------------------- */
//Get Filter

async function hadleLoadBeneficiarioFilter(loadOptions) {
  const { auth } = handleGetSessionStorage();

  const params = {
    // nome: '',
    idPessoaEmpresa: auth.empresa,
  };
  const newParams = handleParams(params, loadOptions);
  const url = `/Beneficiario/GetPorEmpresa?${qs.stringify(newParams)}`;
  return await api
    .get(url)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => {
      handleError(err.response);
    });
}

async function handleDeleteBeneficiario(key) {
  const url = `/Beneficiario/Id/${key}`;
  return await api
    .delete(url)
    .then((res) =>
      swal({
        title: "Deletado com sucesso",
        icon: "success",
      })
    )
    .catch((err) => handleError(err));
}

export const BeneficiarioFilterStore = {
  store: new CustomStore({
    key: "id",
    remove: (key) => handleDeleteBeneficiario(key),
    load: (loadOptions) => {
      return hadleLoadBeneficiarioFilter(loadOptions);
    },
  }),
};
