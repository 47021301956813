import {
  Button,
  Column,
  DataGrid,
  Editing,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
} from 'devextreme-react/data-grid';
import React, { useState } from 'react';
import { postAndPut } from './object';
import { PastoralFilterStore } from './PastoralService';

export function PastoralFilter({ setForm, setToggle }) {
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(15);

  const applyFilterTypes = [
    {
      key: 'auto',
      name: 'Immediately',
    },
    {
      key: 'onClick',
      name: 'On Button Click',
    },
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };

  function handleOptionChange(event) {
    if (event.fullName === 'paging.pageSize' && event.value !== pageSize) {
      setPageSize(event.value);
      setPageIndex(0);
    }

    setPageIndex(null);
  }

  function handleEdit(e) {
    setToggle(true);
    const newForm = e.row.data;
    setForm({
      ...postAndPut(newForm),
      uf: e.row.data.sigla,
      nomeCidade: e.row.data.cidade,
      descricaoLogradouro: e.row.data.logradouro,
      id: e.row.data.id,
    });
  }

  return (
    <>
      <DataGrid
        dataSource={PastoralFilterStore}
        remoteOperations={true}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        onOptionChanged={handleOptionChange}
      >
        <Editing mode="row" useIcons allowDeleting />
        <Pager
          allowedPageSizes={[7, 15, 30]}
          visible={true}
          showPageSizeSelector={true}
          showInfo={true}
        />
        <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
        <FilterRow
          visible={filterOptions.showFilterRow}
          applyFilter={filterOptions.currentFilter}
        />
        <HeaderFilter />

        <Column
          dataField="nome"
          filterOperations={false}
          allowEditing={false}
          alignment="left"
        >
          <HeaderFilter groupInterval={70} />
        </Column>

        <Column type="buttons" width={100}>
          <Button hint="Editar" visible icon="edit" onClick={handleEdit} />
          <Button name="delete" visible hint="Deletar" icon="trash" />
        </Column>
      </DataGrid>
    </>
  );
}
