import React from 'react';
import { Col, FlexboxGrid } from 'rsuite';
import MaskInput from '../../../components/form/maskInput/MaskInput';
import TextField from '../../../components/form/textField/TextField';

export default function CnpjNome({ form, setForm }) {
  return (
    <FlexboxGrid align="top" style={{ marginTop: '16px' }}>
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
        <MaskInput
          label="CNPJ"
          mask={{
            blocks: [2, 3, 3, 4, 2],
            delimiters: ['.', '.', '/', '-'],
            numericOnly: true,
          }}
          value={form.cnpj}
          style={{ margin: 8 }}
          margin="0"
          width="100%"
          placeholder="Insira o cnpj"
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
        <TextField
          width="100%"
          label="Nome"
          name="nome"
          placeholder="Insira o Nome"
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
