import { useRef } from "react";
import swal from "sweetalert";
import { logout } from "../pages/login/LoginService";
import { Auth, AccountType, Company } from "../config/Storage";
import api from "./api";
import qs from "qs";
import { toggleModal } from "../store/modules/company/action";

export function useDebounce(fn, delay) {
  const timeoutRef = useRef(null);

  function debounceFn(...args) {
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      fn(...args);
    }, delay);
  }

  return debounceFn;
}

export function cpfMask(value) {
  return value
    .toString()
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
}

export function cnpjMask(value) {
  return value
    .toString()
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
}

export function handleMask(value) {
  if (!value) return value;
  const newValue = value.toString();
  if (newValue.length === 11) {
    return cpfMask(value);
  } else if (newValue.length === 14) {
    return cnpjMask(value);
  }

  return value;
}

export function filterNonNull(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([k, v]) => v !== null && v !== 0)
  );
}

export function filterNonValue(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([k, v]) => v !== null || v !== 0)
  );
}

export const formatDate = (data) => {
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const date = new Date(data);
  const dateFormated = date.toLocaleDateString("pt-br", options);
  return dateFormated;
};

export const formatDateFull = (data) => {
  let date = data ? new Date(data) : new Date();
  let day = date.getDate().toString().padStart(2, "0");
  let hour = date.getHours().toString().padStart(2, "0");
  let minute = date.getMinutes().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();

  return `${day}/${month}/${year} ${hour}:${minute}`;
};

export const formatMonthYear = (date) => {
  if (!date)
    return {
      mes: null,
      ano: null,
    };

  let month = (new Date(date).getMonth() + 1).toString().padStart(2, "0");
  let year = new Date(date).getFullYear();

  return {
    mes: month,
    ano: Number(year),
  };
};

export const formatCurrency = (value) => {
  if (!value) return "0,00";

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

export function formatCurrencyInFloat(value) {
  if (!value) return 0;
  const valueString = value.toString();
  const formatedValue = valueString
    .replace(".", "")
    .replace(",", ".")
    .replace("R$ ", "");
  return Number(formatedValue);
}

export const handleError = (error, action) => {
  if (!error) {
    return swal(
      "Algo de errado aconteceu!",
      "Verifique sua conexão de internet.",
      "error"
    );
  }

  const { data = "" } = error;
  let message = "";
  let status = "";

  if (data) {
    status = data.status;

    const errorMessage = Object.keys(data.errors).map(
      (key) => data.errors[key]
    );
    // eslint-disable-next-line no-useless-escape
    message = `${status} - ${errorMessage.join(",").replace(/\,/, ", ")}`;
  } else {
    status = error.status;
  }

  switch (status) {
    case 400:
      message = message ? message : "Verifique os dados enviados";
      break;
    case 409:
      break;
    case 401:
      logout();
      break;
    case 404:
      console.log("Não encontrado");
      break;
    case 500:
      message = "Erro no servidor";
      break;
    default:
      message = "Erro desconhecido!";
  }

  if (message) swal("Algo de errado aconteceu!", message, "error");

  if (action) action();
};

export function handleErrorForm(method, error) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (!error?.response) error = { response: { data: false, status: false } };
  const { data, status } = error?.response;
  let message = "";

  if (data) {
    const errorMessage = Object.keys(data.errors).map(
      (key) => data.errors[key]
    );
    // eslint-disable-next-line no-useless-escape
    message =
      typeof errorMessage[0] === "object"
        ? errorMessage[0][0]
        : // eslint-disable-next-line no-useless-escape
          errorMessage.join(",").replace(/\,/, ", ");
  }

  switch (status) {
    case 400:
      message = message ?? "Verifique os dados enviados";
      break;
    case 401:
      logout();
      break;
    case 404:
      message = "Nenhum registro encontrado!";
      break;
    case 409:
      message = message ?? "Conflito na atualização do registro!";
      break;
    case 500:
      message = message ?? "Erro no servidor";
      break;
    default:
      message = "Erro desconhecido!";
      break;
  }

  if (message) return `${method} (${status}) - ${message}`;
}

export const handleGetSessionStorage = () => {
  return {
    auth: sessionStorage.getItem(Auth)
      ? JSON.parse(sessionStorage.getItem(Auth))
      : {},
    accountType: sessionStorage.getItem(AccountType),
    company: sessionStorage.getItem(Company)
      ? JSON.parse(sessionStorage.getItem(Company)).codigoParoquia
      : 0,
    token: sessionStorage.getItem(Auth)
      ? JSON.parse(sessionStorage.getItem(Auth)).accessToken
      : "",
  };
};

export const handleSetSessionStorage = (option, value) => {
  switch (option) {
    case "auth":
      sessionStorage.setItem(Auth, value);
      break;
    case "company":
      sessionStorage.setItem(Company, value);
      break;
    case "accountType":
      sessionStorage.setItem(AccountType, value);
      break;
    default:
      console.log("Chave não encontrada!");
      break;
  }
};

export function handleParams(params, options) {
  const { skip = 0, take = 20, filter } = options;

  if (filter) {
    let arrayNomes = [];
    const filterResult = filter.filter((item) => item !== "and");
    const checkFilterObject =
      filterResult.length === 3 && typeof filterResult[0] === "string";

    if (checkFilterObject) {
      let field = filterResult[0];
      let value = filterResult[2];
      params[field] = value;
    } else {
      filterResult.map((item) => {
        if (!arrayNomes.includes(item[0])) {
          if (typeof item[0] === "object") {
            let field = item[0][0];
            let value = item[0][2];

            params[field] = value;
          } else {
            let field = item[0];
            let value = item[2];

            params[field] = value;
          }
          arrayNomes.push(item[0]);
        }
        return item;
      });
    }
  }

  return {
    ...params,
    skip,
    take,
  };
}

export default function removerAcentos(newStringComAcento) {
  let string = newStringComAcento.toLowerCase();
  let mapaAcentosHex = {
    a: /[\xE0-\xE6]/g,
    e: /[\xE8-\xEB]/g,
    i: /[\xEC-\xEF]/g,
    o: /[\xF2-\xF6]/g,
    u: /[\xF9-\xFC]/g,
    c: /\xE7/g,
    n: /\xF1/g,
  };

  for (let letra in mapaAcentosHex) {
    let expressaoRegular = mapaAcentosHex[letra];
    string = string.replace(expressaoRegular, letra);
  }

  return string;
}

export function removerAcentosEspacos(newStringComAcentoEspaco) {
  return newStringComAcentoEspaco.normalize("NFD").replace(/[^a-zA-Zs]/g, "");
}

export const mes = [
  {
    name: "Janeiro",
    value: 1,
  },
  {
    name: "Fevereiro",
    value: 2,
  },
  {
    name: "Março",
    value: 3,
  },
  {
    name: "Abril",
    value: 4,
  },
  {
    name: "Maio",
    value: 5,
  },
  {
    name: "Junho",
    value: 6,
  },
  {
    name: "Julho",
    value: 7,
  },
  {
    name: "Agosto",
    value: 8,
  },
  {
    name: "Setembro",
    value: 9,
  },
  {
    name: "Outubro",
    value: 10,
  },
  {
    name: "Novembro",
    value: 11,
  },
  {
    name: "Dezembro",
    value: 12,
  },
];

export function loginByDashboard(token, setLoading, dispatch) {
  let params = {
    token,
    idModulo: 18,
  };
  api
    .get(`Autenticacao/AutenticacaoDashboard?${qs.stringify(params)}`)
    .then((res) => {
      if (res.data.statusCode >= 400 && res.data.statusCode <= 499) {
        return false;
      } else {
        sessionStorage.setItem(Auth, JSON.stringify(res.data));
        dispatch(toggleModal(true));
      }
    })
    .catch((err) => {
      console.error(err);
      return false;
    })
    .finally(() => setLoading(false));
}
