import PropTypes from 'prop-types';
import React from 'react';

import '../Form.css';
import { FormGroup, ControlLabel, DatePicker } from 'rsuite';
import removerAcentosEspacos from '../../../services/helpers';

const DateInput = ({
  label,
  form,
  value,
  setForm,
  invalid,
  error,
  state,
  disabled,
  width,
  format,
  oneTap,
  margin,
}) => {
  return (
    <div style={{ margin: margin || 8 }}>
      <FormGroup
        style={{ width: width || 240 }}
        className="rs-form-control-wrapper"
      >
        <ControlLabel>{label}</ControlLabel>
        <DatePicker
          format={format || 'DD/MM/YYYY'}
          name={removerAcentosEspacos(label)}
          disabled={disabled}
          locale={{
            sunday: 'Do',
            monday: 'Se',
            tuesday: 'Te',
            wednesday: 'Qa',
            thursday: 'Qi',
            friday: 'Sx',
            saturday: 'Sa',
            ok: 'Confirmar',
            today: 'Hoje',
            yesterday: 'Ontem',
            last7Days: '7 dias atrás',
          }}
          oneTap={oneTap || true}
          className="formInput"
          style={{ zIndex: 0, width: width || 240 }}
          componentClass="input"
          value={value !== '' ? value : null}
          onChange={(value) =>
            setForm({
              ...form,
              [state]: value,
            })
          }
        />
        {invalid && <p className="formInputError">{error}</p>}
      </FormGroup>
    </div>
  );
};

DateInput.propTypes = {
  error: PropTypes.any,
  form: PropTypes.any,
  invalid: PropTypes.any,
  label: PropTypes.any,
  setForm: PropTypes.func,
  state: PropTypes.any,
  value: PropTypes.any,
};

export default DateInput;
