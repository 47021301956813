import qs from "qs";
import CustomStore from "devextreme/data/custom_store";
import {
  handleGetSessionStorage,
  handleError,
  handleParams,
} from "../../services/helpers";
import store from "../../store/Store";
import api from "../../services/api";

function handleInsert(values) {
  const { auth } = handleGetSessionStorage();
  const record = {
    id: null,
    idPessoaEmpresa: auth.empresa,
    ...values,
    usuario: auth.nomeUsuario,
    excluido: false,
  };

  return api
    .post("/Nacionalidade", record)
    .then((res) => res)
    .catch((err) => handleError("Erro ao incluir", err));
}

function handleLoad(loadOptions) {
  const { auth } = handleGetSessionStorage();
  const record = {
    idPessoaEmpresa: auth.empresa,
  };
  const newRecord = handleParams(record, loadOptions);
  const url = `/Nacionalidade/GetPorEmpresa?${qs.stringify(newRecord)}`;

  return api
    .get(url)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao incluir", err));
}

function handleUpdate(values) {
  const { selected } = store.getState().Nacionalidade;

  return api
    .put("/Nacionalidade", selected)
    .then((res) => res)
    .catch((err) => handleError("Erro ao incluir", err));
}

function handleRemove({ id }) {
  return api
    .delete(`/Nacionalidade/id/${id}`)
    .then((res) => res)
    .catch((err) => handleError("Erro ao incluir", err));
}

export const gridDataSource = {
  store: new CustomStore({
    key: ["id"],
    load: handleLoad,
    insert: handleInsert,
    update: handleUpdate,
    remove: handleRemove,
  }),
};
