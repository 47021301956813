import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { FlexboxGrid, Form, Schema, Button, FormGroup } from "rsuite";
import Beneficiario from "./Form/Beneficiario";
import Paroquia from "./Form/Paroquia";
import MesAnoData from "./Form/MesAnoData";
import BeneficioGrid from "./Grids/BeneficioGrid";
import { saveAtendimento } from "./AtendimentoService";
import { handleErrorForm } from "../../services/helpers";
import {
  addIdAtendimento,
  addIdBeneficiarioAtendimento,
} from "../../store/modules/atendimento/action";

export default function AtendimentoForm({
  form,
  setForm,
  successMessage,
  errorMessage,
  initialForm,
  dataSourceGrid,
  setDataSourceGrid,
  errors,
  setErrors,
  change,
  setChange,
  list,
  setList,
  errorGrid,
  setErrorGrid,
  errorBeneficiario,
  errorDependente,
  setErrorBeneficiario,
  setErrorDependente,
}) {
  const dispatch = useDispatch();
  let formValid = useRef();

  function handleFormChange(value) {
    setForm({ ...form, ...value });
    setChange(true);
  }

  function handleSubmit(e) {
    if (!dataSourceGrid.length) {
      return setErrorGrid(true);
    }
    if (formValid.check() && !errorBeneficiario && !errorDependente) {
      saveAtendimento(form, change)
        .then((res) => {
          let list = res.listAtendimentoBeneficio.filter(
            (item) => item.status !== 3
          );
          list.map((item) => (item.status = 0));
          setForm({
            ...form,
            id: res.id,
            dataInclusao: res.dataInclusao,
            listAtendimentoBeneficio: list,
          });
          setDataSourceGrid([...list]);
          dispatch(addIdAtendimento(res.id));
          dispatch(addIdBeneficiarioAtendimento(res.idBeneficiario));
          setChange(false);
          successMessage(
            `Atendimento ${form.id ? "atualizado" : "cadastrado"} com sucesso! `
          );
        })
        .catch((err) => errorMessage(handleErrorForm(err)));
    }
  }

  const { StringType, NumberType } = Schema.Types;

  const model = Schema.Model({
    mes: NumberType().isRequired("Mês é obrigatório"),
    ano: NumberType().isRequired("Ano é obrigatório"),
    idBeneficiario: NumberType().isRequired("Beneficiário é obrigatório"),
  });
  return (
    <Form
      style={{ width: "100%" }}
      className="pageForm"
      formValue={form}
      model={model}
      formError={errors}
      onChange={handleFormChange}
      onCheck={setErrors}
      ref={(ref) => (formValid = ref)}
      fluid
    >
      <FormGroup className="pageFormContainer" style={{ display: "block" }}>
        <Paroquia form={form} setForm={setForm} />
        <Beneficiario
          form={form}
          setForm={setForm}
          errors={errors}
          setErrors={setErrors}
          setErrorBeneficiario={setErrorBeneficiario}
          errorBeneficiario={errorBeneficiario}
          errorDependente={errorDependente}
          setErrorDependente={setErrorDependente}
          list={list}
          setList={setList}
        />
        <MesAnoData form={form} setForm={setForm} errors={errors} />
        <BeneficioGrid
          style={{ marginTop: "16px" }}
          form={form}
          setForm={setForm}
          dataSourceGrid={dataSourceGrid}
          setDataSourceGrid={setDataSourceGrid}
          errorGrid={errorGrid}
          setErrorGrid={setErrorGrid}
        />
        <FlexboxGrid
          className="marginTop"
          justify="end"
          style={{ marginTop: "32px" }}
        >
          {form.id ? (
            <Button
              type="button"
              appearance="ghost"
              color="blue"
              onClick={() => {
                setForm(initialForm);
                setDataSourceGrid([]);
                setErrors({});
                setChange(false);
                dispatch(addIdAtendimento(0));
                setErrorBeneficiario(false);
                setErrorDependente(false);
                addIdBeneficiarioAtendimento(null);
              }}
              style={{ marginRight: "16px" }}
            >
              Incluir Novo
            </Button>
          ) : null}

          <Button
            type="button"
            appearance="primary"
            color={"green"}
            onClick={handleSubmit}
          >
            {form.id ? "Alterar" : "Salvar"}
          </Button>
        </FlexboxGrid>
      </FormGroup>
    </Form>
  );
}
