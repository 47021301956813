import { DataGrid } from 'devextreme-react';
import {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
} from 'devextreme-react/data-grid';
import React, { useState } from 'react';
import { Checkbox } from 'rsuite';
import api from '../../../services/api';
import {
  handleError,
  handleGetSessionStorage,
} from '../../../services/helpers';
import store from '../../../store/Store';
import { gridDataSourceBeneficioPastoral } from '../BeneficioService';

export default function PastoralModalGrid({ successMessage }) {
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(7);

  const applyFilterTypes = [
    {
      key: 'auto',
      name: 'Immediately',
    },
    {
      key: 'onClick',
      name: 'On Button Click',
    },
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };

  function handleOptionChange(event) {
    if (event.fullName === 'paging.pageSize' && event.value !== pageSize) {
      setPageSize(event.value);
      setPageIndex(0);
    }

    setPageIndex(null);
  }

  async function handleSelectionChanged(e) {
    setLoading(true);
    const { idBeneficio } = store.getState().Beneficio;
    const { auth } = handleGetSessionStorage();

    let data = {
      id: null,
      idPessoaEmpresa: auth.empresa,
      idBeneficio: idBeneficio,
      idPastoral: e.row.data.idPastoral,
      dataInclusao: new Date().toISOString(),
      usuario: auth.nomeUsuario,
      excluido: false,
    };
    const url = 'BeneficioPastoral';

    await api
      .post(url, data)
      .then(() => {
        setLoading(false);
        successMessage('Inserção feita com sucesso');
      })
      .catch((err) => {
        handleError(err.message);
      });

    e.component.refresh();
    setLoading(false);
  }

  async function handleDeleteSelection(e) {
    setLoading(true);

    const url = `BeneficioPastoral/Id/${e.row.data.idBeneficioPastoral}`;
    await api
      .delete(url)
      .then(() => {
        setLoading(false);
        successMessage('Remoção feita com sucesso');
      })
      .catch((err) => {
        handleError(err.message);
      });
    e.component.refresh();
    setLoading(false);
  }

  function checkRender(event) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Checkbox
          disabled={loading}
          defaultChecked={false}
          checked={event.row.data.check}
          onChange={() => {
            if (event.row.data.idBeneficioPastoral) {
              handleDeleteSelection(event);
            } else {
              handleSelectionChanged(event);
            }
          }}
        />
      </div>
    );
  }
  return (
    <DataGrid
      dataSource={gridDataSourceBeneficioPastoral}
      remoteOperations={true}
      showBorders={true}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      onOptionChanged={handleOptionChange}
    >
      <Pager
        allowedPageSizes={[7, 15, 30]}
        visible={true}
        showPageSizeSelector={true}
        showInfo={true}
      />
      <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
      <FilterRow
        visible={filterOptions.showFilterRow}
        applyFilter={filterOptions.currentFilter}
      />
      <HeaderFilter />

      <Column
        dataField="nomePastoral"
        filterOperations={false}
        allowEditing={false}
        alignment="left"
      >
        <HeaderFilter groupInterval={70} />
      </Column>
      <Column
        dataField="cnpj"
        filterOperations={false}
        allowEditing={false}
        alignment="left"
      >
        <HeaderFilter groupInterval={70} />
      </Column>
      <Column
        alignment="center"
        dataField="check"
        caption="Selecionar"
        cellRender={checkRender}
        editCellRender={checkRender}
        width={100}
      />
    </DataGrid>
  );
}
