import api from '../../services/api';
import CustomStore from 'devextreme/data/custom_store';
import {
  handleError,
  handleGetSessionStorage,
  handleParams,
} from '../../services/helpers';
import qs from 'qs';

export function handleLoadDiocese(loadOptions) {
  const { auth } = handleGetSessionStorage();
  const params = {
    idPessoaEmpresa: auth.empresa,
  };
  const newParams = handleParams(params, loadOptions);
  const url = `/Diocese/GetDiocese?${qs.stringify(newParams)}`;

  return api
    .get(url)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((error) => {
      handleError(error.response);
      return [];
    });
}

export function handleLoadRegiao(loadOptions, idDiocese) {
  const params = {
    idDiocese: idDiocese,
  };
  const newParams = handleParams(params, loadOptions);
  const url = `/Diocese/GetRegiao?${qs.stringify(newParams)}`;
  return api
    .get(url)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((error) => {
      handleError(error.response);
      return [];
    });
}

export function handleLoadParoquia(loadOptions, idRegiao) {
  const { auth } = handleGetSessionStorage();
  const params = {
    idRegiao: idRegiao,
    nomeUsuario: auth.nomeUsuario,
  };
  const newParams = handleParams(params, loadOptions);
  const url = `/Diocese/GetParoquia?${qs.stringify(newParams)}`;

  return api
    .get(url)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((error) => {
      handleError(error.response);
      return [];
    });
}

export const dioceseStore = new CustomStore({
  key: 'id',
  load: handleLoadDiocese,
});

export function getRegiaoStore({ idDiocese }) {
  return {
    store: new CustomStore({
      key: 'id',
      load: (loadOptions) => handleLoadRegiao(loadOptions, idDiocese),
    }),
  };
}

export function getParoquiaStore({ idRegiao }) {
  return {
    store: new CustomStore({
      key: 'id',
      load: (loadOptions) => handleLoadParoquia(loadOptions, idRegiao),
    }),
  };
}
