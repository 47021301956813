import PropTypes from "prop-types";
import React from "react";
import { ControlLabel, FormControl, FormGroup } from "rsuite";

const TextField = (props) => {
  const { label, name, accepter, error, width, textarea, id } = props;
  return (
    <>
      <div>
        <FormGroup>
          <ControlLabel htmlFor={id}>{label}</ControlLabel>
          <FormControl
            className="formInput"
            name={name}
            componentClass={textarea ? "textarea" : "input"}
            style={{ resize: textarea ? "auto" : "none", width: width ?? 300 }}
            accepter={accepter}
            errorMessage={error}
            {...props}
          />
        </FormGroup>
      </div>
    </>
  );
};

TextField.propTypes = {
  accepter: PropTypes.any,
  error: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
};

export default TextField;
