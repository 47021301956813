/* eslint-disable no-unused-expressions */
import React from "react";
import { DatePicker } from "rsuite";
import InputReport from "../../components/inputReport/InputReport";
import TextFieldCurrency from "../../components/textFieldCurrency/TextFieldCurrency";
import MaskInput from "../../components/form/maskInput/MaskInput";
import InputModal from "../../components/inputModal/InputModal";
import DropdownReport from "../../components/form/dropdownReport/DropdownReport";

const DynamicInput = ({
  list,
  setList,
  filter,
  setFilter,
  toggleModal,
  namePage,
  idRelatorio,
  returnListVariable,
  user,
}) => {
  const handleChange = async (value, name, index) => {
    list[index].value = value;
    setList([...list]);
    setFilter({ ...filter, [name]: value });
  };

  const handleChangeDropdown = async (value, descricao, name, index) => {
    list[index].value = value;
    list[index].descricaoValor = descricao;
    setList([...list]);
    setFilter({ ...filter, [name]: value });
  };

  const handleClearDropdown = async (name, index) => {
    list[index].value = null;
    list[index].descricaoValor = null;
    setList([...list]);
    setFilter({ ...filter, [name]: "" });
  };

  const inputType = (params, index) => {
    switch (params.tipo) {
      case "text":
        return (
          <InputReport
            maxLength={params.tamanho}
            placeholder={`Digite ${params.descricao}`}
            onChange={(e) => handleChange(e, params.conteudo, index)}
            type={params.tipo}
            name={params.conteudo}
            label={params.descricao}
            value={params.value}
            error={params.error ? "Este campo é obrigatório" : null}
          />
        );
      case "textarea":
        return (
          <InputReport
            label={params.descricao}
            textarea={true}
            name={params.conteudo}
            value={params.value}
            maxLength={params.tamanho}
            placeholder={`Digite ${params.descricao}`}
            onChange={(e) => handleChange(e, params.conteudo, index)}
            error={params.error ? "Este campo é obrigatório" : null}
          ></InputReport>
        );
      case "number":
        return (
          <MaskInput
            label={params.descricao}
            mask={{ numericOnly: true, blocks: [params.tamanho] }}
            type="number"
            value={params.value}
            placeholder={`Digite ${params.descricao}`}
            style={{ margin: 8 }}
            onChange={(value) => handleChange(value, params.conteudo, index)}
            error={params.error ? "Este campo é obrigatório" : null}
            invalid={params.error}
          />
        );
      case "money":
        return (
          <TextFieldCurrency
            name={params.conteudo}
            label={params.descricao}
            value={params.value}
            onChange={(e, value) => handleChange(value, params.conteudo, index)}
            error={params.error ? "Este campo é obrigatório" : null}
          />
        );
      case "date":
        return (
          <InputReport
            name={params.conteudo}
            label={params.descricao}
            format="DD/MM/YYYY"
            accepter={DatePicker}
            oneTap
            width={200}
            value={params.value}
            onChange={(e) => handleChange(e, params.conteudo, index)}
            error={params.error ? "Este campo é obrigatório" : null}
          />
        );
      case "dateHour":
        return (
          <InputReport
            name={params.conteudo}
            label={params.descricao}
            format="DD/MM/YYYY HH:mm:ss"
            accepter={DatePicker}
            oneTap
            width={200}
            value={params.value}
            onChange={(e) => handleChange(e, params.conteudo, index)}
            error={params.error ? "Este campo é obrigatório" : null}
          />
        );
      case "rg":
        return (
          <MaskInput
            label={params.descricao}
            mask={{
              blocks: [2, 3, 3, 1],
              delimiters: [".", ".", "-"],
              numericOnly: true,
            }}
            error={params.error ? "Este campo é obrigatório" : null}
            value={params.value}
            style={{ margin: 8 }}
            invalid={params.error}
            onChange={(e) => handleChange(e, params.conteudo, index)}
          />
        );
      case "cpf":
        return (
          <MaskInput
            label={params.descricao}
            mask={{
              blocks: [3, 3, 3, 2],
              delimiters: [".", ".", "-"],
              numericOnly: true,
            }}
            error={params.error ? "Este campo é obrigatório" : null}
            value={params.value}
            style={{ margin: 8 }}
            invalid={params.error}
            onChange={(e) => handleChange(e, params.conteudo, index)}
          />
        );
      case "cep":
        return (
          <MaskInput
            label={params.descricao}
            mask={{ blocks: [5, 3], delimiter: "-", numericOnly: true }}
            error={params.error ? "Este campo é obrigatório" : null}
            value={params.value}
            onChange={(e) => handleChange(e, params.conteudo, index)}
            style={{ margin: 8 }}
            invalid={params.error}
          />
        );
      case "telefone":
        return (
          <MaskInput
            label={params.descricao}
            mask={{
              blocks: [2, 5, 4],
              delimiters: [" ", "-"],
            }}
            style={{ margin: 8 }}
            error={params.error ? "Este campo é obrigatório" : null}
            value={params.value}
            invalid={params.error}
            onChange={(e) => handleChange(e, params.conteudo, index)}
          />
        );

      case "gridmodal":
        let arrayIds = localStorage.getItem(
          `${user} - ${namePage} - ${params.descricao}`
        );
        params.value = arrayIds;

        return (
          <InputModal
            label={params.descricao}
            onClick={() => toggleModal(params)}
            descricao={
              JSON.parse(arrayIds)?.length &&
              `Total de ${JSON.parse(arrayIds)?.length} registros`
            }
            placeholder="Clique para selecionar"
            error={params.error ? "Este campo é obrigatório" : null}
          ></InputModal>
        );
      case "dropdown":
        return (
          <DropdownReport
            params={params}
            handleChange={(value, descricao) =>
              handleChangeDropdown(value, descricao, params.conteudo, index)
            }
            handleClearDropdown={() =>
              handleClearDropdown(params.conteudo, index)
            }
            idRelatorio={idRelatorio}
            returnListVariable={returnListVariable}
            error={params.error ? "Este campo é obrigatório" : null}
          />
        );

      default:
        return (
          <InputReport
            maxLength={params.tamanho}
            placeholder={`Digite ${params.descricao}`}
            onChange={(e) => handleChange(e, params.conteudo, index)}
            type={params.tipo}
            name={params.conteudo}
            label={params.descricao}
            error={params.error ? "Este campo é obrigatório" : null}
          />
        );
    }
  };

  return (
    list &&
    list.length > 0 &&
    list.map((item, i) => {
      return <>{item.visivel && inputType(item, i)}</>;
    })
  );
};

export default DynamicInput;
