import PropTypes from "prop-types";
import React from "react";
import { FormGroup, ControlLabel, FormControl } from "rsuite";

const InputReport = (props) => {
  const { label, name, margin, accepter, error, width, textarea } = props;
  return (
    <>
      <div style={{ margin: margin ?? 8, width: width ?? 300 }}>
        <FormGroup>
          <ControlLabel> {label} </ControlLabel>
          <FormControl
            name={name}
            componentClass={textarea ? "textarea" : "input"}
            style={{ resize: textarea ? "auto" : "none", width: width ?? 300 }}
            accepter={accepter}
            errorMessage={error}
            {...props}
          />
        </FormGroup>
      </div>
    </>
  );
};

InputReport.propTypes = {
  accepter: PropTypes.any,
  error: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
};

export default InputReport;
