import React, { useState } from "react";
import {
  Column,
  DataGrid,
  Editing,
  HeaderFilter,
  FilterRow,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import { gridDataSource } from "./NacionalidadeService";
import { useDispatch } from "react-redux";
import { selectItem } from "../../store/modules/nacionalidade/action";

export default function NacionalidadeGrid({ successMessage, warningMessage }) {
  const dispatch = useDispatch();
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(15);

  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };

  function handleOptionChange(event) {
    if (event.fullName === "paging.pageSize" && event.value !== pageSize) {
      setPageSize(event.value);
      setPageIndex(0);
    }

    setPageIndex(null);
  }

  function handleInsert(event) {
    successMessage("Cadastro feito com sucesso");
  }

  function handleInsertError(event) {
    const { newData } = event;
    const required = typeof newData.descricao !== "string";
    const length = !required ? newData.descricao.length > 50 : false;

    event.isValid = !required && !length;

    if (required) warningMessage("Informe sua nacionalidade");
    if (length) warningMessage("O limite de caracteres é de 50");
  }

  function handleRemove({ data }) {
    successMessage("Nacionalidade deletada com sucesso");
  }

  function handleUpdate(event) {
    successMessage("Alteração feita com sucesso");
  }

  function handleUpdating({ oldData, newData }) {
    dispatch(
      selectItem({
        ...oldData,
        ...newData,
      })
    );
  }

  return (
    <>
      <DataGrid
        dataSource={gridDataSource}
        remoteOperations={true}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        onRowInserted={handleInsert}
        onRowRemoved={handleRemove}
        onRowUpdated={handleUpdate}
        onRowUpdating={handleUpdating}
        onRowValidating={handleInsertError}
        onOptionChanged={handleOptionChange}
      >
        <Pager
          allowedPageSizes={[7, 15, 30]}
          visible={true}
          showPageSizeSelector={true}
          showInfo={true}
        />
        <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
        <Editing mode="row" allowAdding allowDeleting allowUpdating useIcons />
        <FilterRow
          visible={filterOptions.showFilterRow}
          applyFilter={filterOptions.currentFilter}
        />
        <HeaderFilter />

        <Column dataField="descricao" filterOperations={false}>
          <HeaderFilter groupInterval={100} />
        </Column>
      </DataGrid>
    </>
  );
}
