import {
  Button,
  Column,
  DataGrid,
  Editing,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import React, { useState } from "react";
import {
  addIdAtendimento,
  addIdBeneficiarioAtendimento,
} from "../../store/modules/atendimento/action";
import { dataSourceAtendimento } from "./AtendimentoService";
import { useDispatch } from "react-redux";

export default function AtendimentoFilter({
  setDataSourceGrid,
  setToggle,
  setForm,
  successMessage,
  setErrorBeneficiario,
  setErrorDependente,
  setErrors,
  setErrorGrid,
}) {
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const dispatch = useDispatch();
  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };

  function handleOptionChange(event) {
    if (event.fullName === "paging.pageSize" && event.value !== pageSize) {
      setPageSize(event.value);
      setPageIndex(0);
    }

    setPageIndex(null);
  }

  function handleRemove({ data }) {
    successMessage("Atendimento deletado com sucesso");
  }

  return (
    <>
      <DataGrid
        dataSource={dataSourceAtendimento}
        remoteOperations={true}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        onOptionChanged={handleOptionChange}
        onRowRemoved={handleRemove}
      >
        <Editing mode="row" useIcons allowDeleting />
        <Pager
          allowedPageSizes={[7, 15, 30]}
          visible={true}
          showPageSizeSelector={true}
          showInfo={true}
        />
        <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
        <FilterRow
          visible={filterOptions.showFilterRow}
          applyFilter={filterOptions.currentFilter}
        />
        <HeaderFilter />
        <Column
          dataField="nomeParoquia"
          caption="Nome Paróquia"
          filterOperations={false}
          allowEditing={false}
          alignment="left"
        >
          <HeaderFilter groupInterval={70} />
        </Column>
        <Column
          dataField="dataInclusao"
          caption="Data Hora/Atendimento"
          dataType="date"
          format="dd/MM/yyyy HH:mm"
          filterOperations={false}
          allowEditing={false}
          alignment="left"
        >
          <HeaderFilter groupInterval={70} />
        </Column>
        <Column
          dataField="nomeBeneficiario"
          caption="Beneficiário"
          filterOperations={false}
          allowEditing={false}
          alignment="left"
        >
          <HeaderFilter groupInterval={70} />
        </Column>

        <Column
          dataField="nomeBeneficiarioDependente"
          caption="Dependente"
          filterOperations={false}
          allowEditing={false}
        >
          <HeaderFilter groupInterval={70} />
        </Column>

        <Column
          dataField="mes"
          caption="Mês"
          filterOperations={false}
          allowEditing={false}
          alignment="left"
        >
          <HeaderFilter groupInterval={70} />
        </Column>

        <Column
          dataField="ano"
          filterOperations={false}
          allowEditing={false}
          alignment="left"
        >
          <HeaderFilter groupInterval={70} />
        </Column>

        <Column
          dataField="dataRetorno"
          caption="Data Retorno"
          dataType="date"
          format="dd/MM/yyyy"
          filterOperations={false}
          allowEditing={false}
          alignment="left"
        >
          <HeaderFilter groupInterval={70} />
        </Column>

        <Column type="buttons" width={100}>
          <Button
            hint="Editar"
            visible
            icon="edit"
            onClick={(e) => {
              setToggle(true);
              setForm(e.row.data);
              setDataSourceGrid([...e.row.data.listAtendimentoBeneficio]);
              dispatch(addIdAtendimento(e.row.data.id));
              dispatch(addIdBeneficiarioAtendimento(e.row.data.idBeneficiario));
              setErrorBeneficiario(false);
              setErrorDependente(false);
              setErrors({});
              setErrorGrid(false);
            }}
          />
          <Button name="delete" visible hint="Deletar" icon="trash" />
        </Column>
      </DataGrid>
    </>
  );
}
