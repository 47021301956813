import { useState } from "react";
import { useToasts } from "react-toast-notifications";

import { Form, FormGroup } from "rsuite";
import FilterModal from "../../components/filter/FilterModal";
import "../Page.css";
import { BeneficiarioFilter } from "./BeneficiarioFilter";
import BeneficiarioForm from "./BeneficiarioForm";

const initialForm = {
  nome: "",
  cep: "",
  idLogradouro: null,
  endereco: "",
  numero: "",
  complemento: "",
  bairro: "",
  idCidade: null,
  idUf: null,
  rg: "",
  cpf: "",
  telefone: "",
  celular: "",
  telefoneRecado: "",
  nascimento: "",
  sexo: "",
  idEstadoCivil: null,
  observacoes: "",
  profissao: "",
  nomeUsuario: "",
  descricaoLogradouro: "",
  uf: "",
  nomeCidade: "",
  email: "",
  descricaoNacionalidade: "",
  idNacionalidade: "",
  descricaoEscolaridade: "",
  idEscolaridade: "",
  descricaoResidencia: "",
  idTipoResidencia: "",
  rendaFamiliar: "",
  descricaoParoquiaInclusao: "",
  listDependenteGrid: [],
  listHistoricoGrid: [],
};

export default function Beneficiario() {
  const [form, setForm] = useState(initialForm);
  const [toggle, setToggle] = useState(false);
  const [dataSourceGridDependentes, setDataSourceGridDependentes] = useState(
    []
  );
  const [dataSourceGridHistorico, setDataSourceGridHistorico] = useState([]);
  const [change, setChange] = useState(false);
  const [errorGrid, setErrorGrid] = useState(false);
  const [cpfCadastrado, setCpfCadastrado] = useState("");
  const [errors, setErrors] = useState({});

  const { addToast } = useToasts();

  function successMessage(message) {
    addToast(message, {
      appearance: "success",
      autoDismiss: true,
    });
  }

  function warningMessage(message) {
    addToast(message, {
      appearance: "warning",
      autoDismiss: true,
    });
  }

  function errorMessage(message) {
    addToast(message, {
      appearance: "error",
      autoDismiss: false,
    });
  }

  return (
    <div className="pageContainer">
      <div className="pageFlex">
        <div className="pageContent withoutSearch">
          <h3>Beneficiário</h3>
          <Form className="pageForm">
            <FormGroup
              className="pageFormContainer"
              style={{ display: "block" }}
            >
              <BeneficiarioForm
                form={form}
                setForm={setForm}
                errorMessage={errorMessage}
                successMessage={successMessage}
                errorGrid={errorGrid}
                setErrorGrid={setErrorGrid}
                warningMessage={warningMessage}
                initialForm={initialForm}
                dataSourceGridDependentes={dataSourceGridDependentes}
                setDataSourceGridDependentes={setDataSourceGridDependentes}
                dataSourceGridHistorico={dataSourceGridHistorico}
                setDataSourceGridHistorico={setDataSourceGridHistorico}
                change={change}
                setChange={setChange}
                cpfCadastrado={cpfCadastrado}
                setCpfCadastrado={setCpfCadastrado}
                errors={errors}
                setErrors={setErrors}
              />
            </FormGroup>
          </Form>
        </div>
        <FilterModal toggle={toggle} setToggle={setToggle} full>
          <BeneficiarioFilter
            form={form}
            setForm={setForm}
            setToggle={setToggle}
            errorMessage={errorMessage}
            successMessage={successMessage}
            initialForm={initialForm}
            dataSourceGridDependentes={dataSourceGridDependentes}
            setDataSourceGridDependentes={setDataSourceGridDependentes}
            dataSourceGridHistorico={dataSourceGridHistorico}
            setDataSourceGridHistorico={setDataSourceGridHistorico}
            setCpfCadastrado={setCpfCadastrado}
            setErrors={setErrors}
            setErrorGrid={setErrorGrid}
          />
        </FilterModal>
      </div>
    </div>
  );
}
