import React, { useState } from "react";
import { DataGrid } from "devextreme-react";
import {
  Button,
  Column,
  Editing,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  Selection,
  Grouping,
  Lookup,
  RequiredRule,
} from "devextreme-react/data-grid";

import { Checkbox } from "rsuite";
import { useEffect } from "react";
import { handleGetSessionStorage } from "../../../services/helpers";
import { gridParentesco } from "../BeneficiarioService";

export default function DependentesGrid({
  form,
  setForm,
  successMessage,
  dataSourceGridDependentes,
  setDataSourceGridDependentes,
}) {
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const [render, setRender] = useState(false);

  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };

  function handleInserting({ data }) {
    const { auth } = handleGetSessionStorage();
    const expense = {
      ...data,
      idPessoaEmpresa: auth.empresa,
      id: null,
      status: 1,
      usuario: auth.nomeUsuario,
      idBeneficiario: form.idBeneficiario || 0,
      excluido: false,
    };

    setForm((form) => ({
      ...form,
      listDependenteGrid: [...form.listDependenteGrid, expense],
    }));

    setDataSourceGridDependentes(dataSourceGridDependentes);
  }

  function handleUpdating({ key }) {
    const { company } = handleGetSessionStorage();
    const { id, __KEY__ } = key;

    if (__KEY__) {
      let dependente = form.listDependenteGrid;
      dependente.forEach((item, index) => {
        if (item.__KEY__ === __KEY__) {
          dependente[index] = {
            ...dependente[index],
            ...key,
            status: 2,
          };
        }
      });
      setForm({
        ...form,
        listDependenteGrid: dependente,
      });
    } else {
      let dependente = form.listDependenteGrid;
      dependente.forEach((item, index) => {
        if (item.id === id) {
          dependente[index] = {
            ...dependente[index],
            ...key,
            status: 2,
          };
        }
      });
      setForm({
        ...form,
        listDependenteGrid: dependente,
      });
    }
  }

  function handleRemove({ key }) {
    const { id, __KEY__ } = key;
    let dependente = form.listDependenteGrid;

    if (__KEY__ && !id) {
      let newList = dependente.filter((item) => item.__KEY__ !== __KEY__);
      setForm({
        ...form,
        listDependenteGrid: newList,
      });
    } else {
      dependente.forEach((item, index) => {
        if (item.id === id) {
          dependente[index] = {
            ...dependente[index],
            ...key,
            status: 3,
          };
        }
      });
      setForm({
        ...form,
        listDependenteGrid: dependente,
      });
    }
  }

  const checkRender = (event) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 10,
        }}
      >
        <Checkbox
          style={{ margin: -10 }}
          checked={event.data.autorizado}
          disabled
        />
      </div>
    );
  };

  const editCheckRender = (event) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 10,
        }}
      >
        <Checkbox
          style={{ margin: -10 }}
          checked={event.data.autorizado}
          onCheckboxClick={() => {
            event.data.autorizado = !event.data.autorizado;
            setRender(!render);
          }}
        />
      </div>
    );
  };

  function handleValidation(e) {
    const { newData, oldData = false } = e;
    newData.autorizado = newData.autorizado ?? false;
  }

  return (
    <>
      <div style={{ marginTop: 16 }}>
        <h5>Dependentes</h5>
        <DataGrid
          dataSource={dataSourceGridDependentes}
          remoteOperations={true}
          showBorders={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onRowRemoved={(event) => handleRemove(event)}
          onRowInserted={(event) => handleInserting(event)}
          onRowUpdated={(event) => handleUpdating(event)}
          onRowValidating={handleValidation}
        >
          {/* <Pager
            allowedPageSizes={[7, 15, 30]}
            visible={true}
            showPageSizeSelector={true}
            showInfo={true}
          /> */}
          <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
          <Editing
            mode="row"
            allowAdding
            allowDeleting
            allowUpdating
            useIcons
          />
          <FilterRow
            visible={filterOptions.showFilterRow}
            applyFilter={filterOptions.currentFilter}
          />

          <HeaderFilter />

          <Column
            // width="125"
            dataField="nome"
            caption="Nome"
            alignment="left"
          >
            <RequiredRule></RequiredRule>
            <HeaderFilter groupInterval={100} />
          </Column>

          <Column
            dataField="dataNascimento"
            caption="Data Nascimento"
            dataType="date"
            format="dd/MM/yyyy"
            alignment="center"
            width={150}
          >
            <RequiredRule></RequiredRule>
            <HeaderFilter groupInterval={100} />
          </Column>

          <Column
            dataField="idParentesco"
            caption="Parentesco"
            filterOperations={false}
            width={180}
          >
            <Lookup
              dataSource={gridParentesco}
              displayExpr="descricao"
              valueExpr="id"
            />
            <RequiredRule></RequiredRule>
          </Column>

          <Column
            alignment="center"
            dataField="autorizado"
            caption="Autorizado?"
            cellRender={checkRender}
            editCellRender={editCheckRender}
            width="125"
          >
            <HeaderFilter groupInterval={100} />
          </Column>
        </DataGrid>
      </div>
    </>
  );
}
