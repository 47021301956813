import React from 'react';
import { FlexboxGrid, Col } from 'rsuite';
import TextField from '../../../components/form/textField/TextField';

export default function SiteCoord() {
  return (
    <FlexboxGrid align="top" style={{ marginTop: '16px' }}>
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={10}>
        <TextField
          width="100%"
          label="Site"
          name="site"
          placeholder="Insira o Site"
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={14}>
        <TextField
          width="100%"
          label="Coordenador"
          name="coordenador"
          placeholder="Insira o Coordenador"
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
