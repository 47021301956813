import React from 'react';
import { FlexboxGrid, Col } from 'rsuite';
import TextField from '../../../components/form/textField/TextField';

export default function Descricao() {
  return (
    <FlexboxGrid align="top" style={{ margin: '16px 0' }}>
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={12}>
        <TextField
          width="100%"
          label="Descrição"
          name="descricao"
          placeholder="Insira o descricao"
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
