import { handleGetSessionStorage } from '../../services/helpers';

export function postAndPut(data) {
  const { auth } = handleGetSessionStorage();
  return {
    id: data.id || null,
    idPessoaEmpresa: auth.empresa,
    cnpj: data.cnpj,
    nome: data.nome,
    cep: data.cep,
    idLogradouro: data.idLogradouro,
    endereco: data.endereco,
    numero: data.numero,
    bairro: data.bairro,
    idCidade: data.idCidade,
    idUf: data.idUf,
    telefone: data.telefone,
    email: data.email,
    site: data.site,
    observacoes: data.observacoes,
    coordenador: data.coordenador,
    usuario: auth.nomeUsuario,
    excluido: false,
  };
}
