import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import BeneficioForm from './BeneficioForm';
import FilterModal from '../../components/filter/FilterModal';
import BeneficioFilter from './BeneficioFilter';
import { useDispatch } from 'react-redux';
import { addIdBeneficio } from '../../store/modules/beneficio/action';

const initialForm = {
  descricao: '',
};

export default function Beneficio() {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [toggle, setToggle] = useState(false);
  const [form, setForm] = useState(initialForm);

  function successMessage(message) {
    addToast(message, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  function warningMessage(message) {
    addToast(message, {
      appearance: 'warning',
      autoDismiss: true,
    });
  }

  function errorMessage(message) {
    addToast(message, {
      appearance: 'error',
      autoDismiss: false,
    });
  }

  return (
    <div className="pageContainer">
      <div className="pageFlex">
        <div className="pageContent withoutSearch">
          <h4>Benefício</h4>
          <BeneficioForm
            form={form}
            setForm={setForm}
            errorMessage={errorMessage}
            successMessage={successMessage}
            warningMessage={warningMessage}
            initialForm={initialForm}
          />
        </div>
        <FilterModal
          toggle={toggle}
          setToggle={setToggle}
          full
          onClick={() => {
            setForm(initialForm);
            dispatch(addIdBeneficio(null));
          }}
        >
          <BeneficioFilter
            setForm={setForm}
            setToggle={setToggle}
            initialForm={initialForm}
          />
        </FilterModal>
      </div>
    </div>
  );
}
