import { combineReducers } from "redux";
import Header from "./header/reducer";
import Sidebar from "./sidebar/reducer";
import Home from "./home/reducer";
import Login from "./login/reducer";
import Company from "./company/reducer";
import Beneficio from "./beneficio/reducer";
import Atendimento from "./atendimento/reducer";
import Escolaridade from "./escolaridade/reducer";
import Nacionalidade from "./nacionalidade/reducer";
import TipoResidencia from "./tipoResidencia/reducer";

export default combineReducers({
  Home,
  Login,
  Header,
  Sidebar,
  Company,
  Beneficio,
  Atendimento,
  Escolaridade,
  Nacionalidade,
  TipoResidencia,
});
