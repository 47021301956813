import PropTypes from "prop-types";
import React from "react";
import listThemes from "./listThemes";

import "./ButtonTheme.css";

const ButtonTheme = ({ setTheme }) => {
  return (
    <>
      {listThemes.map((theme) => (
        <button
          key={theme}
          tabIndex="-1"
          className={`buttonTheme ${"buttonTheme_" + theme}`}
          onClick={() => setTheme(theme)}
        />
      ))}
    </>
  );
};

ButtonTheme.propTypes = {
  setTheme: PropTypes.func,
};

export default ButtonTheme;
