import { handleGetSessionStorage } from "../../services/helpers";

// function setStatusZero(form, method) {
//   if (form.listDocumentosDespesasVo.length) {
//     return form.listDocumentosDespesasVo.map((item) => {
//       const checkStatus =
//         item.idCpDocumentosDespesas !== 0 &&
//         item.status === 1 &&
//         method === 'put';
//       item.status = checkStatus ? 0 : item.status;
//       return item;
//     });
//   }

//   return [];
// }

export function postAndPut(data, change) {
  const { auth, company } = handleGetSessionStorage();

  return {
    id: data.idBeneficiario || null,
    idPessoaEmpresa: auth.empresa,
    nome: data.nome,
    cep: data.cep,
    idLogradouro: data.idLogradouro,
    endereco: data.endereco,
    numero: data.numero,
    complemento: data.complemento,
    bairro: data.bairro,
    idCidade: data.idCidade,
    idUf: data.idUf,
    rg: data.rg,
    cpf: data.cpf,
    telefone: data.telefone,
    celular: data.celular,
    telefoneRecado: data.telefoneRecado,
    dataNascimento: data.nascimento,
    sexo: data.sexo,
    idEstadoCivil: data.idEstadoCivil,
    observacoes: data.observacoes,
    idParoquiaInclusao: company,
    dataInicio: new Date().toISOString(),
    profissao: data.profissao,
    empregado: data.empregadoCheck,
    email: data.email,
    dataInclusao: new Date().toISOString(),
    usuario: auth.nomeUsuario,
    excluido: false,
    status: data.id ? (change ? 2 : 0) : 1,
    idNacionalidade: data.idNacionalidade,
    idEscolaridade: data.idEscolaridade,
    idTipoResidencia: data.idTipoResidencia,
    rendaFamiliar: data.rendaFamiliar,
    beneficioGoverno: data.beneficioGovernoCheck,

    listBeneficiarioDependentes: data.listDependenteGrid,
    listHistoricoAtivacao: data.listHistoricoGrid,
  };
}
