import {
  handleGetSessionStorage,
  handleParams,
  handleError,
} from "../../services/helpers";
import qs from "qs";
import api from "../../services/api";
import CustomStore from "devextreme/data/custom_store";
import { postAndPut } from "./object";
import swal from "sweetalert";

async function hadleLoadPastoralFilter(loadOptions) {
  const { auth } = handleGetSessionStorage();
  const params = {
    idPessoaEmpresa: auth.empresa,
  };
  const newParams = handleParams(params, loadOptions);
  const url = `/Pastoral/GetPorEmpresa?${qs.stringify(newParams)}`;
  return await api
    .get(url)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => {
      handleError(err.response);
    });
}

async function handleDeletePastoral(key) {
  const url = `/Pastoral/Id/${key}`;
  return await api
    .delete(url)
    .then((res) =>
      swal({
        title: "Deletado com sucesso",
        icon: "success",
      })
    )
    .catch((err) => handleError(err));
}

export function handleLoadCity(loadOptions, uf) {
  const params = {
    sigla: uf,
  };
  const newParams = handleParams(params, loadOptions);
  const urlComplete = `/Cidade/GetNomeCidadeUf?${qs.stringify(newParams)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

export function handleLoadLogradouro(loadOptions, logradouro) {
  const params = {
    nome: null,
    logradouro,
  };
  const newParams = handleParams(params, loadOptions);
  const urlComplete = `/Logradouro/GetLogradouro?${qs.stringify(newParams)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

export function handleLoadState(loadOptions) {
  const params = {};
  const newParams = handleParams(params, loadOptions);
  const urlComplete = `/Uf/GetUfs?${qs.stringify(newParams)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

function handleLoadPastoralById(loadOptions, url) {
  const { auth } = handleGetSessionStorage();
  const id = typeof loadOptions === "number" ? loadOptions : null;

  const params = {
    idPastoral: id,
    idPessoaEmpresa: auth.empresa,
    skip: 0,
    take: 1000,
  };
  const urlComplete = `/Pastoral/GetPorEmpresa?${qs.stringify(params)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;

      if (id !== null) {
        return data;
      }

      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

export const gridDataSourceState = {
  store: new CustomStore({
    key: "id",
    load: (loadOptions) => {
      return handleLoadState(loadOptions);
    },
  }),
};

export const PastoralFilterStore = {
  store: new CustomStore({
    key: "id",
    remove: (key) => handleDeletePastoral(key),
    byKey: (loadOptions) => {
      return handleLoadPastoralById(loadOptions);
    },
    load: (loadOptions) => {
      return hadleLoadPastoralFilter(loadOptions);
    },
  }),
};

// Post

export async function handleCreatePastoral(form) {
  const data = postAndPut(form);
  const method = data.id ? "put" : "post";
  const feedback = data.id ? "Alteração feita" : "Cadastro feito";
  const url = "Pastoral";
  return await api({ method, url, data })
    .then((res) => {
      swal({
        title: feedback,
        icon: "success",
      });
      return res.data;
    })
    .catch((err) => {
      swal({
        title: err.message,
        icon: "error",
      });
    });
}
