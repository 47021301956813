import PropTypes from 'prop-types';
import React from 'react';
import { FormGroup, ControlLabel, Tooltip, Whisper } from 'rsuite';
import IntlCurrencyInput from 'react-intl-currency-input';

import './input.css';

const TextFieldCurrency = (props) => {
  const { className, label, error, width, margin } = props;
  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };
  return (
    <div>
      <FormGroup>
        <ControlLabel> {label} </ControlLabel>
        <Whisper
          open={error ? true : false}
          trigger="none"
          placement={'bottomStart'}
          speaker={<Tooltip className="tooltip">{error}</Tooltip>}
        >
          <IntlCurrencyInput
            {...props}
            className={`inputCurrency ${className}`}
            currency="BRL"
            config={currencyConfig}
          />
        </Whisper>
      </FormGroup>
    </div>
  );
};

TextFieldCurrency.propTypes = {
  accepter: PropTypes.any,
  error: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
};

export default TextFieldCurrency;
