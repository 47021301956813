import React from 'react';
import { userHasPermission, getInfo } from '../pages/login/LoginService';
import { FaBan } from 'react-icons/fa';

const RouteAuthorization = (WrappedComponent, name) => {
  return class WithAuthorization extends React.Component {
    render() {
      let allowed = userHasPermission(name);
      if (allowed) {
        return (
          <WrappedComponent
            {...this.props}
            auth={getInfo()}
            style={{ display: `${allowed ? 'initial' : 'none'}` }}
          />
        );
      } else {
        return (
          <div className="container-center">
            <h1 style={{ marginTop: 20 }}>
              Usuário sem acesso a essa área!
              <FaBan style={{ marginLeft: 20, color: 'red' }} />
            </h1>
          </div>
        );
      }
    }
  };
};

export default RouteAuthorization;
