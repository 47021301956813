import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as FontAwesome from "react-icons/fa";

import { Auth } from "../../config/Storage";

import { toggleMenu } from "../../store/modules/sidebar/action";
import { tabsHeader } from "../../store/modules/header/action";
import { logout } from "../../store/modules/login/action";

import history from "../../services/history";
import "./SidebarStyle.css";

export default function SideBar({ location }) {
  // const history = useHistory();
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();

  const { isOpenMenu } = useSelector((state) => ({
    isOpenMenu: state.Sidebar.isOpenMenu,
  }));
  const { tabs } = useSelector((state) => ({
    tabs: state.Header.tabs,
  }));
  var timeOutOpen;

  const menuList =
    sessionStorage.getItem(Auth) &&
    JSON.parse(sessionStorage.getItem(Auth)).module
      ? JSON.parse(sessionStorage.getItem(Auth)).module[0].menu
      : [];
  const user = JSON.parse(sessionStorage.getItem(Auth))?.nomeUsuario;
  const [selected, setSelected] = useState([]); //níveis submenus selecionados
  const [tabsOpen, setTabsOpen] = useState([]);
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    let menusStorage = localStorage.getItem(`${user} - menus`)
      ? JSON.parse(localStorage.getItem(`${user} - menus`))
      : [];
    setMenus(menusStorage);
  }, []);

  useEffect(() => {
    handleToggleMenuItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    //verifica click fora sidebar
    let storageTabs = localStorage.getItem(`${user} - tabs`, tabs);
    storageTabs = JSON.parse(storageTabs);
    storageTabs ? setTabsOpen(storageTabs) : setTabsOpen([]);
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        closeMenu(isOpenMenu);
      }
    }

    // bind event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // unbind event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef, isOpenMenu]);

  useEffect(() => {
    tabs && setTabsOpen(tabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, tabs);

  function closeMenu(statusMenu) {
    if (statusMenu) {
      setSelected([]);
      dispatch(toggleMenu(false));
    }
  }

  const newTab = (item) => {
    let tabExists = false;
    let menuExist = false;
    tabsOpen.map((value) => {
      if (item.descricao === value.tab.descricao) {
        tabExists = true;
      }
      return value;
    });
    menus.map((value) => {
      if (item.descricao === value.descricao) {
        menuExist = true;
      }
      return value;
    });
    if (!tabExists) {
      tabsOpen.unshift({ tab: item });
      if (!menuExist) {
        if (menus.length < 5) {
          menus.unshift(item);
        } else {
          menus.splice(4, 1);
          menus.unshift(item);
        }
        localStorage.setItem(`${user} - menus`, JSON.stringify(menus));
      }
    }
    dispatch(tabsHeader([...tabsOpen]));

    localStorage.setItem(`${user} - tabs`, JSON.stringify([...tabsOpen]));
    localStorage.setItem("activeTab", JSON.stringify(item.descricao));

    handleToggleMenuItem();
  };
  //limpa lista níveis selecionados e fecha sidebar
  const handleToggleMenuItem = async () => {
    clearTimeout(timeOutOpen);
    setSelected([]);
    await dispatch(toggleMenu(false));
  };

  //renderiza items submenu
  const handleToggleMenuItemSubMenu = async ({ id, level }) => {
    clearTimeout(timeOutOpen);
    await dispatch(toggleMenu(true));
    let newListSelected = [];

    if (selected && selected.length) {
      let exist = selected.findIndex((item) => item === id);
      if (exist > -1) {
        newListSelected = selected.slice(0, exist); //fecha listas até nível
      } else {
        selected[level] = id;
        selected.map(
          (item, index) => index <= level && newListSelected.push(item)
        ); //abre novo nível de subitems
      }
    } else newListSelected.push(id); //abre primeiro nível de subitems

    setSelected(newListSelected);
    dispatch(toggleMenu(true));
  };

  const SideBarLogo = ({ isOpen }) => {
    return (
      <div className="sideBarLogoContainer">
        <div className={`sideBarLogo logoClose ${isOpen && "displayNone"}`} />
        <div className={`sideBarLogo logoOpen ${!isOpen && "displayNone"}`} />
      </div>
    );
  };

  //item menu sem submenu, link direto
  const ItemSingle = (item) => {
    const Icon = FontAwesome[item.nomeImagem];
    return (
      <Link
        className="sideBarItem"
        to={{
          pathname: `/${item.url}`,
          key: item.id,
          state: { idRelatorio: item.idRelatorio, nome: item.descricao },
        }}
        onClick={() => newTab(item)}
        id={item.id}
      >
        {item.nomeImagem ? (
          <Icon size="24" />
        ) : (
          <FontAwesome.FaChevronRight size="24" />
        )}
        <span className={`navbar__brand ${isOpenMenu && "displayBlock"}`}>
          {item.descricao}
        </span>
      </Link>
    );
  };

  //item menu com submenu
  const ItemMultiple = (item, count) => {
    count++;
    item.level = count;
    const Icon = FontAwesome[item.nomeImagem];
    return (
      <>
        <div
          style={{ cursor: "pointer" }}
          className={
            selected[count] === item.id
              ? "sideBarItem sideBarItemSelected"
              : "sideBarItem"
          }
          onClick={() => handleToggleMenuItemSubMenu(item)}
          id={item.id}
        >
          {item.nomeImagem ? (
            <Icon size="24" />
          ) : (
            <FontAwesome.FaList size="24" />
          )}
          <span className={`navbar__brand ${isOpenMenu && "displayBlock"}`}>
            {item.descricao}
          </span>
          {isOpenMenu && (
            <FontAwesome.FaAngleDown
              size="24"
              className={`
          arrow
          ${selected[count] === item.id && "selectedSubmenuArrow"}
          `}
            />
          )}
        </div>

        {selected[count] === item.id && (
          <div className="sideBarSubMenuItem" style={{ paddingLeft: 10 }}>
            {item.menuSub.map((i, index) => (
              <SideBarItem item={i} count={count} key={index} />
            ))}
          </div>
        )}
      </>
    );
  };

  //rederiza items menu
  const SideBarItem = ({ item, count = -1 }) => {
    return (
      <>
        {!item.menuSub.length || item.tipoPagina === "O"
          ? ItemSingle(item)
          : ItemMultiple(item, count)}
      </>
    );
  };

  return (
    <>
      <div
        ref={wrapperRef}
        className={`sideBarContainer ${isOpenMenu && "show displayBlock"}`}
      >
        <div
          onClick={() => handleToggleMenuItem()}
          style={{ cursor: "pointer" }}
        >
          <SideBarLogo isOpen={isOpenMenu} />
        </div>

        <Link
          className="sideBarItem"
          id="home"
          to="/"
          onClick={() => {
            handleToggleMenuItem();
            localStorage.setItem("activeTab", JSON.stringify("home"));
          }}
          style={{ marginRight: 6 }}
        >
          <FontAwesome.FaHome size="24" />
          <span className={`navbar__brand ${isOpenMenu && "displayBlock"}`}>
            Home
          </span>
        </Link>

        <div className="sideBarScroll">
          {menuList.map((item, index) => (
            <SideBarItem item={item} key={index} />
          ))}
        </div>

        {!menuList && isOpenMenu && (
          <p className="text-white margin-left">Sem opções disponíveis</p>
        )}

        <Link
          onClick={() => dispatch(logout(history))}
          to=""
          id="sair"
          className="sideBarItem out"
          style={{ marginRight: 6 }}
        >
          <FontAwesome.FaSignOutAlt size="24" />
          <span className={`navbar__brand ${isOpenMenu && "displayBlock"}`}>
            Sair
          </span>
        </Link>
      </div>
    </>
  );
}
