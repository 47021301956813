import React, { useEffect, useState } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'rsuite';
import SelectDataPickerModal from './SelectDataPickerModal';

export default function SelectDataPicker({
  columns,
  dataSource,
  disabled = false,
  editValue = null,
  formData,
  label,
  displayExpr,
  onChange,
  placeholder = '',
  valueExpr = '',
  error = '',
  value,
  ...rest
}) {
  const [description, setDescription] = useState('');
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setDescription(editValue);
  }, [editValue]);

  function checkIfHasValue() {
    if (!value) return;
  }

  function toggleModal(event) {
    setToggle(!toggle);
    if (event) {
      setDescription(event[displayExpr]);
      onChange(event);
    }
  }

  return (
    <>
      <SelectDataPickerModal
        dataSource={dataSource}
        colums={columns}
        title={label}
        toggle={toggle && !disabled}
        toggleModal={toggleModal}
        style={{ maxWidth: '100%' }}
        {...rest}
      />
      <FormGroup onClick={() => (!disabled ? toggleModal() : null)}>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          name={valueExpr}
          errorMessage={error}
          placeholder={placeholder}
          value={description}
          disabled={disabled}
          onChange={checkIfHasValue}
          style={{ width: '100%' }}
          autoComplete="off"
          readOnly={true}
        />
      </FormGroup>
    </>
  );
}
