import React, { useEffect, useState } from "react";
import { DataGrid } from "devextreme-react";
import {
  Column,
  Editing,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  RequiredRule,
} from "devextreme-react/data-grid";
import { handleGetSessionStorage } from "../../../services/helpers";
import { gridDataSourceBeneficioFilter } from "../../Beneficio/BeneficioService";
import TextFieldCurrency from "../../../components/textFieldCurrency/TextFieldCurrency";
import { handleLoadPastoral } from "../AtendimentoService";

export default function BeneficioGrid({
  form,
  setForm,
  setDataSourceGrid,
  dataSourceGrid,
  errorGrid,
  setErrorGrid,
}) {
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const [listPastoral, setListPastoral] = useState([]);

  useEffect(() => {
    handleLoadPastoral().then((res) => setListPastoral(res.data));
  }, []);

  function handleInserting({ data }) {
    const { company, auth } = handleGetSessionStorage();

    const expense = {
      ...data,
      idPessoaEmpresa: auth.empresa,
      id: null,
      status: 1,
      usuario: auth.nomeUsuario,
      idAtendimento: form.id || 0,
      excluido: false,
      dataInclusao: new Date(),
      valor: data.valor || 0,
    };
    setForm((form) => ({
      ...form,
      listAtendimentoBeneficio: [...form.listAtendimentoBeneficio, expense],
    }));

    setDataSourceGrid(dataSourceGrid);
    setErrorGrid(false);
  }

  function handleUpdating(event) {
    const data = event.data ? event.data : event.key;
    if (data.__KEY__) {
      let list = form.listAtendimentoBeneficio;
      list.forEach((item, index) => {
        if (item.__KEY__ === data.__KEY__) {
          list[index] = {
            ...list[index],
            ...data,
            status: 2,
          };
        }
      });
      setForm({
        ...form,
        listAtendimentoBeneficio: list,
      });
    } else {
      let list = form.listAtendimentoBeneficio;

      list.forEach((item, index) => {
        if (item.id === data.id) {
          list[index] = {
            ...list[index],
            ...data,
            status: 2,
          };
        }
      });
      setForm({
        ...form,
        listAtendimentoBeneficio: list,
      });
    }
    setDataSourceGrid(dataSourceGrid);
  }

  function handleValidation(e) {
    const { newData, oldData = false } = e;
    if (newData.valor) {
      newData.valor = newData.valor;
    } else if (oldData.valor) {
      newData.valor = oldData.valor;
    } else {
      newData.valor = 0;
    }
  }

  function handleRemove({ key }) {
    const { id, __KEY__ } = key;
    let list = form.listAtendimentoBeneficio;
    if (__KEY__ && !id) {
      let newList = list.filter((item) => item.__KEY__ !== __KEY__);
      setForm({
        ...form,
        listAtendimentoBeneficio: newList,
      });
    } else {
      list.forEach((item, index) => {
        if (item.id === id) {
          list[index] = {
            ...list[index],
            ...key,
            status: 3,
          };
        }
      });
      setForm({
        ...form,
        listAtendimentoBeneficio: list,
      });
    }
  }

  function handleEditorPreparing(e) {
    if (e.parentType === "dataRow" && e.dataField === "idPastoral") {
      e.editorOptions.disabled = typeof e.row.data.idBeneficio !== "number";
    }
  }
  function handleSetValue(rowData, value) {
    rowData.idBeneficio = value;
    rowData.idPastoral = null;
  }

  function getFilteredPastoral(options) {
    return {
      store: listPastoral,
      filter: options.data
        ? ["idBeneficio", "=", options.data.idBeneficio]
        : null,
    };
  }
  return (
    <>
      <DataGrid
        dataSource={dataSourceGrid}
        remoteOperations={true}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        onRowInserting={(event) => handleInserting(event)}
        onRowUpdated={(event) => handleUpdating(event)}
        onRowValidating={handleValidation}
        onRowRemoved={(event) => handleRemove(event)}
        onEditorPreparing={handleEditorPreparing}
      >
        <Pager
          allowedPageSizes={[7, 15, 30]}
          visible={true}
          showPageSizeSelector={true}
          showInfo={true}
        />
        <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
        <Editing mode="row" allowAdding allowDeleting allowUpdating useIcons />

        <HeaderFilter />

        <Column
          caption="Benefício"
          dataField="idBeneficio"
          filterOperations={false}
          alignment="left"
          setCellValue={handleSetValue}
        >
          <Lookup
            dataSource={gridDataSourceBeneficioFilter}
            displayExpr="descricao"
            valueExpr="id"
          />
          <RequiredRule></RequiredRule>
        </Column>

        <Column
          dataField="idPastoral"
          filterOperations={false}
          caption="Pastoral"
        >
          <Lookup
            dataSource={getFilteredPastoral}
            displayExpr="nomePastoral"
            valueExpr="idPastoral"
          />
          <RequiredRule></RequiredRule>
        </Column>

        <Column dataField="quantidade" filterOperations={false}>
          <HeaderFilter groupInterval={70} />
          <RequiredRule></RequiredRule>
        </Column>

        <Column
          dataField="valor"
          filterOperations={false}
          calculateCellValue={(rowData) => {
            return (
              `R$ ${rowData?.valor?.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              })}` ?? "R$ 0,00"
            );
          }}
          editCellComponent={(rowData) => {
            return (
              <TextFieldCurrency
                className="inputMaskCurrency"
                width={100}
                margin={0}
                onChange={(e, value) => {
                  value > 0
                    ? rowData.data.setValue(value, "valor")
                    : rowData.data.setValue(0, "valor");
                }}
                value={rowData.data.value}
              />
            );
          }}
        >
          <HeaderFilter groupInterval={70} />
          <RequiredRule></RequiredRule>
        </Column>
      </DataGrid>
      {errorGrid ? (
        <div style={{ color: "#f44336", fontWeight: "bold" }}>
          *É necessário adicionar um benefício para o atendimento
        </div>
      ) : null}
    </>
  );
}
