export function addIdAtendimento(idAtendimento) {
  return {
    type: "ADD_ID_ATENDIMENTO",
    idAtendimento,
  };
}

export function addIdBeneficiarioAtendimento(idBeneficiario) {
  return {
    type: "ADD_ID_BENEFICIARIO",
    idBeneficiario,
  };
}
