import { cnpj } from 'cpf-cnpj-validator';
import React, { useRef, useState } from 'react';
import { Button, FlexboxGrid, Form, FormGroup, Schema } from 'rsuite';
import CnpjNome from './Form/CnpjNome';
import { Endereco } from './Form/Endereco';
import Observacao from './Form/Observacao';
import SiteCoord from './Form/SiteCoord';
import { handleCreatePastoral } from './PastoralService';

export default function PastoralForm({ form, setForm, initialForm }) {
  function handleFormChange(value) {
    setForm({ ...form, ...value });
  }
  let formValid = useRef();

  const [errors, setErrors] = useState({});
  async function handleSubmit(e) {
    if (formValid.check()) {
      e.preventDefault();
      await handleCreatePastoral(form).then((res) => {
        const { id } = res;
        if (!id) return;
        setForm({ ...form, id });
      });
    }
  }

  const { StringType } = Schema.Types;

  const model = Schema.Model({
    cnpj: StringType().addRule((value) => {
      return cnpj.isValid(value);
    }, 'CNPJ deve ser válido'),
    nome: StringType().isRequired('Nome é requirido'),
    email: StringType().isEmail('Email deve ser válido.'),
  });

  return (
    <Form
      formValue={form}
      onChange={handleFormChange}
      onSubmit={(e) => handleSubmit(e)}
      formError={errors}
      onCheck={setErrors}
      ref={(ref) => (formValid = ref)}
      model={model}
      fluid
      style={{ width: '100%' }}
      className="pageForm"
    >
      <FormGroup className="pageFormContainer" style={{ display: 'block' }}>
        <CnpjNome form={form} setForm={setForm} />
        <Endereco form={form} setForm={setForm} />
        <SiteCoord />
        <Observacao />
        <>
          {form.id ? (
            <FlexboxGrid
              className="marginTop"
              justify="end"
              style={{ marginTop: '32px' }}
            >
              <Button
                type="button"
                appearance="ghost"
                color="blue"
                onClick={() => {
                  setForm(initialForm);
                }}
                style={{ marginRight: '16px' }}
              >
                Incluir Novo
              </Button>
              <Button
                type="button"
                appearance="primary"
                color="green"
                onClick={(e) => handleSubmit(e)}
              >
                Alterar
              </Button>
            </FlexboxGrid>
          ) : (
            <FlexboxGrid
              className="marginTop"
              justify="end"
              style={{ marginTop: '32px' }}
            >
              <Button
                type="button"
                appearance="primary"
                color="green"
                onClick={(e) => handleSubmit(e)}
              >
                Salvar
              </Button>
            </FlexboxGrid>
          )}
        </>
      </FormGroup>
    </Form>
  );
}
