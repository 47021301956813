import swal from "sweetalert";
import qs from "qs";
import api from "../../services/api";
import {
  handleError,
  handleGetSessionStorage,
  handleParams,
} from "../../services/helpers";
import { postAndPut } from "./object";
import CustomStore from "devextreme/data/custom_store";
import store from "../../store/Store";
import { Company } from "../../config/Storage";

/* Crud Beneficio */

export async function handleCreateBeneficio(form) {
  const data = postAndPut(form);
  const method = data.id ? "put" : "post";
  const feedback = data.id ? "Alteração feita" : "Cadastro feito";
  const url = "Beneficio";
  return await api({ method, url, data })
    .then((res) => {
      swal({
        title: feedback,
        icon: "success",
      });
      return res.data;
    })
    .catch((err) => {
      swal({
        title: err.message,
        icon: "error",
      });
    });
}

export function handleLoadBeneficiosFilter(loadOptions) {
  const { auth } = handleGetSessionStorage();
  const params = {
    idPessoaEmpresa: auth.empresa,
  };
  const newParams = handleParams(params, loadOptions);
  const urlComplete = `/Beneficio/GetPorEmpresa?${qs.stringify(newParams)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

function handleLoadBeneficiosById(loadOptions, url) {
  const { auth } = handleGetSessionStorage();
  const id = typeof loadOptions === "number" ? loadOptions : null;

  const params = {
    id,
    idPessoaEmpresa: auth.empresa,
    skip: 0,
    take: 1000,
  };
  const urlComplete = `/Beneficio/GetPorEmpresa?${qs.stringify(params)}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;

      if (id !== null) {
        return data;
      }

      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

async function handleDeleteBeneficio(key) {
  const url = `/Beneficio/Id/${key}`;
  return await api
    .delete(url)
    .then((res) =>
      swal({
        title: "Deletado com sucesso",
        icon: "success",
      })
    )
    .catch((err) => handleError(err));
}

/* Crud Beneficio Pastoral */

export const gridDataSourceBeneficioFilter = {
  store: new CustomStore({
    key: "id",
    remove: (key) => handleDeleteBeneficio(key),
    byKey: handleLoadBeneficiosById,
    load: (loadOptions) => {
      return handleLoadBeneficiosFilter(loadOptions);
    },
  }),
};

function handleLoadBeneficioPastoral(loadOptions) {
  const { idBeneficio } = store.getState().Beneficio;
  const { idDiocese } = JSON.parse(sessionStorage.getItem(Company));
  const params = {
    idBeneficio: idBeneficio,
    idDiocese: idDiocese,
  };
  const newParams = handleParams(params, loadOptions);
  const urlComplete = `/BeneficioPastoral/GetPastoralPorDiocese?${qs.stringify(
    newParams
  )}`;

  return api
    .get(urlComplete)
    .then((res) => {
      const { data, totalCount } = res.data;
      return {
        data,
        totalCount,
      };
    })
    .catch((err) => handleError("Erro ao carregar", err));
}

export const gridDataSourceBeneficioPastoral = {
  store: new CustomStore({
    key: "idPastoral",
    load: (loadOptions) => {
      return handleLoadBeneficioPastoral(loadOptions);
    },
  }),
};
