import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Nav, Button, Input, Loader, FlexboxGrid, Form } from "rsuite";
import { getInputs, postLog } from "./RelatorioService";
import "./Relatorio.css";
import { FiX } from "react-icons/fi";
import DynamicInput from "./DynamicInput";
import DynamicModal from "./DynamicModal";
import Viewer from "./Viewer";
import { Auth, Company } from "../../config/Storage";
import { useToasts } from "react-toast-notifications";
import handleSystemVariable from "./SystemVariable";
import "../Page.css";

const Report = (props) => {
  const auth = sessionStorage.getItem(Auth)
    ? JSON.parse(sessionStorage.getItem(Auth))
    : { token: null, idUsuario: null };

  const company = JSON.parse(sessionStorage.getItem(Company));

  const { addToast } = useToasts();
  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState([]);
  const [nameReport, setNameReport] = useState("");
  const [filter, setFilter] = useState({});
  const [idRelatorio, setIdRelatorio] = useState(0);
  const [visibleModal, setVisibleModal] = useState(false);
  const [listVariables, setlistVariables] = useState([]);
  const [composicaoGrid, setComposicaoGrid] = useState([]);
  const [inputSelected, setInputSelected] = useState({});
  const [namePage, setNamePage] = useState("");
  const [inputsVisibles, setInputsVisibles] = useState(0);
  const [navs, setNavs] = useState({
    inputs: [],
    activeNav: 1,
    properties: [
      {
        number: 1,
        filters: {},
        name: "FILTROS",
      },
    ],
  });

  useEffect(() => {
    setFilter({});
    setLoading(true);
    setIdRelatorio(props?.location?.state?.idRelatorio);
    setNamePage(props?.location?.state?.nome);
    let navsStorage = localStorage.getItem(
      `${auth?.nomeUsuario} - ${props?.location?.state?.nome}`
    );
    navsStorage = JSON.parse(navsStorage);
    navsStorage
      ? setNavs(navsStorage)
      : setNavs({
          inputs: [],
          activeNav: 1,
          properties: [
            {
              number: 1,
              filters: {},
              name: "FILTROS",
            },
          ],
        });

    if (navsStorage && navsStorage.inputs.length) {
      let obj = {};
      navsStorage.inputs.map((item) => {
        obj[item.conteudo] = item.value;
        return item;
      });

      let countInputsVisibles = 0;
      navsStorage.inputs.map((item) => {
        if (item.visivel) {
          countInputsVisibles += 1;
        }
      });
      setInputsVisibles(countInputsVisibles);

      setFilter(obj);
      setInputs(navsStorage.inputs);
      setLoading(false);
    } else {
      getInputs(props?.location?.state?.idRelatorio)
        .then((res) => {
          handlePopulateFilter(res);
          handleValueComposicaoGrid(res);
          handleSystemVariable(filter, setFilter, res, setInputs);
          let countInputsVisibles = 0;
          res.map((item) => {
            if (item.visivel) {
              countInputsVisibles += 1;
            }
          });
          setInputsVisibles(countInputsVisibles);
        })
        .catch(() => {
          addToast("Algo de errado aconteceu!", {
            appearance: "error",
            autoDismiss: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  function handlePopulateFilter(list) {
    let objFilter = {};
    list.map((item) => {
      objFilter = {
        ...objFilter,
        [item.conteudo]: null,
      };
    });
    setFilter(objFilter);
  }

  function handleValueComposicaoGrid(res) {
    res.map((item) => {
      if (item.tipo === "gridmodal") {
        item.valueComposicaogrid = item.composicaoGrid[0].operador;
      }
      return item;
    });
    setInputs([...res]);
  }

  const toggleOpenModal = useCallback((params) => {
    setVisibleModal(true);
    setComposicaoGrid(params.composicaoGrid);
    setInputSelected(params);
    handleListVariable(params.variaveisTroca);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function toggleCloseModal() {
    setVisibleModal(false);
  }

  function handleListVariable(list) {
    list.map((item) => {
      switch (item.nomeFiltro) {
        case "EMPRESA":
          item.value = company?.codigoParoquia;
          break;
        case "NOMEUSUARIO":
          item.value = auth?.nomeUsuario;
          break;
        case "IDREDECREDENCIADA":
          item.value = auth?.idRedeCredenciada;
          break;
        case "IDCLIENTE":
          item.value = auth?.idCliente;
          break;
        default:
          break;
      }
      return item;
    });
    setlistVariables(list);
  }

  function returnListVariable(list) {
    list.map((item) => {
      switch (item.nomeFiltro) {
        case "EMPRESA":
          item.value = company?.codigoParoquia;
          break;
        case "NOMEUSUARIO":
          item.value = auth?.nomeUsuario;
          break;
        case "IDREDECREDENCIADA":
          item.value = auth?.idRedeCredenciada;
          break;
        case "IDCLIENTE":
          item.value = auth?.idCliente;
          break;
        default:
          return item;
      }
    });
    return list;
  }

  const renderInput = useMemo(() => {
    return (
      <DynamicInput
        list={inputs}
        setList={setInputs}
        filter={filter}
        setFilter={setFilter}
        toggleModal={toggleOpenModal}
        namePage={namePage}
        idRelatorio={idRelatorio}
        returnListVariable={returnListVariable}
      />
    );
  }, [inputs, filter]);

  const handleNavs = (param) => {
    let auxObj = {
      ...navs,
      activeNav: param,
    };
    setNavs({ ...auxObj });
    setNameReport(null);
    localStorage.setItem(
      `${auth?.nomeUsuario} - ${namePage}`,
      JSON.stringify(auxObj)
    );
  };

  const handleChange = (value) => {
    setNameReport(value);
  };

  const verifyRequired = () => {
    let countError = 0;
    inputs.map((item) => {
      if (!item.value && item.campoObrigatorio) {
        item.error = true;
      } else {
        item.error = false;
      }
      return item;
    });
    setInputs([...inputs]);

    inputs.map((item) => {
      if (item.error) {
        countError += 1;
      }
      return item;
    });

    if (countError === 0) {
      openNav();
    }
  };

  const openNav = () => {
    let objFilter = { ...filter };
    let objFilterOperator = {};
    inputs.map((item) => {
      if (item.tipo === "gridmodal") {
        let checkedIds = localStorage.getItem(
          `${auth?.nomeUsuario} - ${namePage} - ${item.descricao}`
        );

        objFilter = { ...objFilter, [item.conteudo]: checkedIds };
        objFilterOperator = {
          ...objFilterOperator,
          [item.conteudo]: item.valueComposicaogrid,
        };
      }
      return item;
    });
    setFilter({ ...objFilter });

    postLog(idRelatorio, objFilter, objFilterOperator, auth?.nomeUsuario).then(
      (res) => {
        navs.properties.push({
          number: navs.properties.length + 1,
          name: nameReport ? nameReport : `RELATÓRIO ${navs.properties.length}`,
          id: res.id,
        });

        let auxObj = {
          activeNav: navs.properties.length,
          inputs: inputs,
          properties: navs.properties,
        };

        setNavs({ ...auxObj });

        localStorage.setItem(
          `${auth?.nomeUsuario} - ${namePage}`,
          JSON.stringify(auxObj)
        );
      }
    );
  };

  const closeNav = (i) => {
    let auxObj = { ...navs, activeNav: 1 };
    setNavs({ ...auxObj });

    navs.properties.splice(i, 1);
    navs.properties.map((item, i) => {
      navs.properties[i].number = i + 1;
      return item;
    });
    localStorage.setItem(
      `${auth?.nomeUsuario} - ${namePage}`,
      JSON.stringify(auxObj)
    );
  };

  const clearFilter = () => {
    inputs.map((item, i) => {
      if (!item.variavelSistema) {
        inputs[i].value = "";
        inputs[i].descricaoValor = "";
        return item;
      }
    });
    for (let property in localStorage) {
      if (property.includes(`${auth?.nomeUsuario} - ${namePage} -`)) {
        localStorage.removeItem(property);
      }
    }
    setInputs([...inputs]);
    setFilter({});
    setNameReport("");
    let auxObj = {
      ...navs,
      inputs: [],
    };
    localStorage.setItem(
      `${auth?.nomeUsuario} - ${namePage}`,
      JSON.stringify(auxObj)
    );
  };

  return (
    <>
      <DynamicModal
        visible={visibleModal}
        toggleModal={toggleCloseModal}
        idSql={inputSelected?.idSqlParametro}
        nameStorage={`${auth?.nomeUsuario} - ${namePage} - ${inputSelected?.descricao}`}
        props={namePage}
        filter={filter}
        setFilter={setFilter}
        listVariables={listVariables}
        composicaoGrid={composicaoGrid}
        inputs={inputs}
        setInputs={setInputs}
        inputSelected={inputSelected}
        setInputSelected={setInputSelected}
        idRelatorio={idRelatorio}
        user={auth?.nomeUsuario}
      ></DynamicModal>

      <div className="pageContainer">
        <div className="pageFlex">
          <div className="pageContent withoutSearch" style={{ width: "95%" }}>
            <h4> {namePage}</h4>

            <Nav
              style={{ marginTop: 15 }}
              appearance="tabs"
              activeKey={navs.activeNav}
            >
              {navs.properties &&
                navs.properties.map((item, i) => (
                  <Nav.Item
                    className="navs"
                    style={{ width: "25%" }}
                    eventKey={item.number}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => handleNavs(item.number)}
                        className="nameReport"
                      >
                        {item.name}
                      </div>
                      {item.number !== 1 && (
                        <div className="iconClose" title="Fechar">
                          <FiX size={15} onClick={() => closeNav(i)}></FiX>
                        </div>
                      )}
                    </div>
                  </Nav.Item>
                ))}
            </Nav>
            {navs.activeNav === 1 && (
              <>
                {loading ? (
                  <div className="center">
                    <Loader size="sm" />
                  </div>
                ) : inputs.length ? (
                  <>
                    {inputsVisibles ? (
                      <div style={{ fontSize: 18, marginTop: 15 }}>
                        Informe os filtros do relatório:
                      </div>
                    ) : null}
                    <Form>
                      <FlexboxGrid>{renderInput}</FlexboxGrid>
                    </Form>
                    <div
                      className=" margin-top "
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 30,
                        width: "100%",
                      }}
                    >
                      {inputsVisibles ? (
                        <div
                          className="btn btn-clear"
                          onClick={() => clearFilter()}
                        >
                          Limpar filtros
                        </div>
                      ) : null}

                      <Input
                        placeholder="Digite o nome do relatório"
                        style={{
                          display: "flex",
                          maxWidth: 200,
                          borderRadius: ".25rem 0px 0px .25rem",
                        }}
                        value={nameReport}
                        maxLength={40}
                        onChange={handleChange}
                      ></Input>
                      <Button
                        style={{
                          maxHeight: "38px",
                          borderRadius: "0px .25rem .25rem 0px",
                          fontWeight: "bold",
                        }}
                        appearance="primary"
                        disabled={navs.properties.length === 4 ? true : false}
                        onClick={() => verifyRequired()}
                      >
                        Visualizar relatório
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="center" style={{ marginTop: 15 }}>
                    Não foi possível carregar os filtros. Tente novamente!
                  </div>
                )}
              </>
            )}
            {navs.properties &&
              navs.properties.map((item, i) => (
                <Viewer
                  key={i}
                  token={auth.accessToken}
                  idRelatorio={idRelatorio}
                  id={item.id}
                  number={item.number}
                  activeNav={navs.activeNav}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
