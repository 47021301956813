import apiReport from "../../services/apiReport";

export function postLog(idRelatorio, objFilter, objFilterOperator, usuario) {
  let observacao = {
    pIdRelatorio: idRelatorio,
    pParamsFiltro: objFilter,
    pParamsOperador: objFilterOperator,
  };
  const data = {
    idRelatorio,
    idSql: null,
    dataInclusao: new Date(),
    observacao: JSON.stringify(observacao),
    usuario,
    excluido: false,
  };
  return new Promise((resolve, reject) => {
    apiReport
      .post(`/LogExecucao`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getInputs(idRelatorio) {
  return new Promise((resolve, reject) => {
    apiReport
      .get(
        `/RelatorioParametro/GetRelatoriosPorPermissao/Relatorio/${idRelatorio}`
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDados(idSql, listaVariaveis, idRelatorio) {
  const data = {
    idSqlParametro: idSql,
    listaVariaveis,
    idRelatorio,
  };
  return new Promise((resolve, reject) => {
    apiReport
      .post(`/RelatorioParametro/GetDadosSqlParametro`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDadosDropdown(idSql, listaVariaveis, idRelatorio) {
  const data = {
    idSqlParametro: idSql,
    listaVariaveis,
    idRelatorio,
  };
  return new Promise((resolve, reject) => {
    apiReport
      .post(`RelatorioParametro/GetDropDownDados`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
