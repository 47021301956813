import api from "../../services/api";
import { Auth } from "../../config/Storage";
import history from "../../services/history";

const user = JSON.parse(sessionStorage.getItem(Auth));

export function login(user, password, handleError, handleSuccess) {
  api
    .post("Autenticacao", {
      login: user.toString().toUpperCase(),
      senha: password,
      idModulo: 18,
    })
    .then((res) => {
      if (res.data.statusCode >= 400 && res.data.statusCode <= 499) {
        handleError("Verifique o usuário e senha");
      } else {
        sessionStorage.setItem(Auth, JSON.stringify(res.data));
        handleSuccess();
      }
    })
    .catch((err) => {
      handleError("Erro ao efetuar o login, por favor tente novamente");
    });
}

export function userHasPermission(menuName) {
  const userStore = JSON.parse(sessionStorage.getItem(Auth));
  if (userStore !== null && userStore.module !== null) {
    const menuList = userStore.module?.length ? userStore.module[0].menu : [];
    if (!menuList) return false;
    for (let menuItem of menuList) {
      if (menuItem?.nome === menuName || menuName === "home") {
        return true;
      } else if (menuItem?.menuSub) {
        let contemMenu = subMenuItemFunction(menuItem?.menuSub, menuName);
        if (contemMenu) {
          return true;
        }
      }
    }
    return false;
  }
}

function subMenuItemFunction(menuSub, menuName) {
  for (let subMenuItem of menuSub) {
    if (subMenuItem?.url === menuName) {
      return true;
    } else if (subMenuItem?.menuSub) {
      let contemMenu = subMenuItemFunction(subMenuItem?.menuSub, menuName);
      if (contemMenu) {
        return true;
      }
    }
  }
  return false;
}

export function logout() {
  sessionStorage.clear();
  history.replace("/login");
}

export function getInfo() {
  if (!user) {
    return {};
  } else {
    return {
      company: user.empresa,
      authToken: `Bearer ${user.accessTokenAutenticacao}`,
      token: `Bearer ${user.accessToken}`,
      user: user.nomeUsuario,
    };
  }
}
