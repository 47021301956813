import arrayMove from "array-move";
import React, { useEffect, useState } from "react";
import {
  FiChevronLeft,
  FiChevronRight,
  FiLogOut,
  FiMenu,
  FiX,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroupButtonDropdown,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { Auth } from "../../config/Storage";
import { toggleModal } from "../../store/modules/company/action";
import { tabsHeader } from "../../store/modules/header/action";
import { logout } from "../../store/modules/login/action";
import { toggleMenu } from "../../store/modules/sidebar/action";
import ButtonTheme from "../buttonTheme/ButtonTheme";
import "./HeaderStyle.css";

export default function Header(props) {
  const history = useHistory();

  const dispatch = useDispatch();
  const { isOpenMenu } = useSelector((state) => ({
    isOpenMenu: state.Sidebar.isOpenMenu,
  }));
  const { tabs } = useSelector((state) => ({
    tabs: state.Header.tabs,
  }));

  const [tabsOpen, setTabsOpen] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const { nomeUsuario: user } = JSON.parse(sessionStorage.getItem(Auth));
  const letterUser = user
    ? user.charAt(0).toUpperCase()
    : "sem-usuario".charAt(0).toUpperCase();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    let storageTabs = localStorage.getItem(`${user} - tabs`, tabs);
    let storageActive = localStorage.getItem("activeTab", activeTab);

    storageTabs = JSON.parse(storageTabs);
    storageActive = JSON.parse(storageActive);
    setTabsOpen(storageTabs);
    setActiveTab(storageActive);
  }, [tabs, activeTab]);

  useEffect(() => {
    tabs && setTabsOpen(tabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, tabs);

  function handleToggleMenu() {
    dispatch(toggleMenu(!isOpenMenu));
  }

  function handleToggleModal() {
    dispatch(toggleModal(true));
  }

  const handleTheme = (theme) => {
    localStorage.setItem("theme", theme);
    props.setTheme(theme);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let newTabs = arrayMove(tabsOpen, oldIndex, newIndex);
    setTabsOpen(newTabs);
    localStorage.setItem(`${user} - tabs`, JSON.stringify([...newTabs]));
  };

  const closeTab = (item, index) => {
    tabsOpen.splice(index, 1);
    setTabsOpen([...tabsOpen]);
    dispatch(tabsHeader([...tabsOpen]));
    localStorage.setItem(`${user} - tabs`, JSON.stringify([...tabsOpen]));

    if (tabsOpen.length === 0 || item.tab.descricao === activeTab) {
      history.push("/");
    }
  };

  const openTab = (item) => {
    localStorage.setItem("activeTab", JSON.stringify(item.tab.descricao));
    setActiveTab(item.tab.descricao);
    history.push({
      pathname: item.tab.url,
      state: { idRelatorio: item.tab.idRelatorio, nome: item.tab.descricao },
      key: item.tab.id,
    });
  };
  const moveLeftScroll = () => {
    const element = document.getElementById("tabs");

    const positionScroll = element.scrollLeft;
    element.scrollTo({ left: (positionScroll - 200, 0), behavior: "smooth" });
  };

  const moveRightScroll = () => {
    const element = document.getElementById("tabs");

    const positionScroll = element.scrollLeft;
    element.scrollTo({ left: (0, positionScroll + 200), behavior: "smooth" });
  };
  const SortableItem = SortableElement(({ item, sortIndex }) => (
    <div
      className="headerTabs"
      style={{
        backgroundColor: activeTab === item.tab.descricao ? "#d9d9d9" : null,
      }}
      title={item.tab.descricao}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <div style={{ width: "90%" }} onClick={() => openTab(item)}>
          <b className="description">{item.tab.descricao}</b>
        </div>
        <div>
          <FiX onClick={() => closeTab(item, sortIndex)} title="Fechar"></FiX>
        </div>
      </div>
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div className="containerTabs">
        {items && items.length > 1 && (
          <div
            style={{
              paddingTop: 5,
              paddingRight: 10,
              cursor: "pointer",
            }}
            onClick={() => moveLeftScroll()}
          >
            <FiChevronLeft size={25}></FiChevronLeft>
          </div>
        )}
        <div id="tabs" className={`tabs scroll`}>
          {items &&
            items.map((value, index) => (
              <SortableItem
                key={`item-${value.tab.descricao}`}
                sortIndex={index}
                index={index}
                className={"scroll"}
                item={value}
              />
            ))}
        </div>
        {items && items.length > 1 && (
          <div
            style={{
              padding: "5px 0px 0px 10px",
              cursor: "pointer",
            }}
            onClick={() => moveRightScroll()}
          >
            <FiChevronRight size={25}></FiChevronRight>
          </div>
        )}
      </div>
    );
  });

  const DropdownList = () => {
    const items = tabsOpen;
    return (
      <div className="altContainerNoTabs">
        <InputGroupButtonDropdown isOpen={dropdownOpen} toggle={toggleDropDown}>
          <DropdownToggle color="link" split>
            Acesso Rápido
          </DropdownToggle>
          <DropdownMenu>
            {items ? (
              <>
                <DropdownItem divider />
                {items.map((value, index) => (
                  <>
                    <DropdownItem key={index} onClick={() => openTab(value)}>
                      {value.tab.descricao}
                    </DropdownItem>
                    <DropdownItem divider />
                  </>
                ))}
              </>
            ) : (
              <DropdownItem disabled>
                Nenhuma aba aberta recentemente
              </DropdownItem>
            )}
          </DropdownMenu>
        </InputGroupButtonDropdown>
      </div>
    );
  };

  const TabsList = () =>
    window.screen.width < 500 ? (
      <DropdownList />
    ) : (
      <SortableList
        distance={1}
        axis="x"
        items={tabsOpen}
        onSortEnd={onSortEnd}
      />
    );

  return (
    <div className="headerContainer">
      <Button
        type="button"
        title="Menu"
        className="buttonGoOut"
        onClick={handleToggleMenu}
      >
        <FiMenu color="#000000" />
      </Button>

      <TabsList />

      <UncontrolledButtonDropdown>
        <DropdownToggle
          className="hero center"
          title={`Usuário logado ${user}`}
          caret
        >
          <b>{letterUser}</b>
        </DropdownToggle>
        <DropdownMenu className="dropdownMenuContainar">
          <DropdownItem header>Usuário Logado</DropdownItem>
          <DropdownItem>
            <b>{user}</b>
          </DropdownItem>
          <DropdownItem onClick={handleToggleModal}>
            Trocar Empresa
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem header>Temas</DropdownItem>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ButtonTheme setTheme={(theme) => handleTheme(theme)}></ButtonTheme>
          </div>
          <DropdownItem divider />
          <DropdownItem
            onClick={() => dispatch(logout(history))}
            title="Sair"
            style={{ display: "flex", alignItems: "center" }}
          >
            <FiLogOut />
            <div className="headerMargin">Sair</div>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  );
}
