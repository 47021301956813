import React from 'react';
import { Col, FlexboxGrid } from 'rsuite';
import TextField from '../../../components/form/textField/TextField';

export default function Observacao() {
  return (
    <FlexboxGrid align="top" style={{ marginTop: '16px' }}>
      <FlexboxGrid.Item componentClass={Col} colspan={24} md={24}>
        <TextField
          rows={5}
          margin="0"
          width="100%"
          componentClass="textarea"
          name="observacoes"
          label="Observação"
          maxLength={1000}
          style={{ minHeight: '37px', maxHeight: '150px' }}
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
