import React, { useState } from "react";
import { FormGroup, FormControl, Button } from "rsuite";
import { FiEye, FiEyeOff, FiUser, FiLock } from "react-icons/fi";

import "./Login.css";

const LoginForm = ({ user, setUser, password, setPassword }) => {
  const [showPassword, setShowPassword] = useState(false);

  const userShowError = !user.value.length > 0 && user.blur;
  const passwordShowError = !password.value.length > 0 && password.blur;
  return (
    <>
      <FormGroup className="loginInputGroup">
        <FiUser className="loginIcon" size="25px" color="#555555" />
        <FormControl
          autoFocus
          onBlur={() => setUser({ ...user, blur: true })}
          onChange={(value) => setUser({ ...user, value: value })}
          placeholder="Usuário"
          className="loginInput loginInputUser"
          errorMessage={userShowError && "Por favor digite o Usuário !"}
        />
      </FormGroup>
      <FormGroup className="loginInputGroup">
        <FiLock className="loginIcon" size="25px" color="#555555" />
        <FormControl
          type={showPassword ? "text" : "password"}
          errorMessage={passwordShowError && "Por favor digite a Senha !"}
          onBlur={() => setPassword({ ...password, blur: true })}
          onChange={(value) => setPassword({ ...password, value: value })}
          placeholder="Senha"
          className="loginInput loginInputPassword"
        />
        <Button
          color="blue"
          className="show-password"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <FiEyeOff size="14pt" /> : <FiEye size="14pt" />}
        </Button>
      </FormGroup>
    </>
  );
};

export default LoginForm;
