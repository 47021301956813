/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import CustomStore from 'devextreme/data/custom_store';
import React from 'react';
import { Col, FlexboxGrid } from 'rsuite';
import MaskInput from '../../../components/form/maskInput/MaskInput';
import TextField from '../../../components/form/textField/TextField';
import SelectPicker from '../../../components/selectPicker/SelectPicker';
import {
  gridDataSourceState,
  handleLoadCity,
  handleLoadLogradouro,
} from '../PastoralService';

export function Endereco({ form, setForm }) {
  const gridDataSourceCity = {
    store: new CustomStore({
      key: 'idCidade',
      byKey: (loadOptions) => handleLoadCity(loadOptions, form.uf),
      load: (loadOptions) => handleLoadCity(loadOptions, form.uf),
    }),
  };

  const gridDataSourceLogradouro = {
    store: new CustomStore({
      key: 'id',
      byKey: (loadOptions) => handleLoadLogradouro(loadOptions),
      load: (loadOptions) => handleLoadLogradouro(loadOptions),
    }),
  };

  async function handleCep(value) {
    if (value.length < 9) return;
    const location = await axios
      .get(`https://viacep.com.br/ws/${value}/json`)
      .then((res) => res.data);
    if (location) {
      const city = await handleCityInfo(location);
      const siglaLogradouro = location.logradouro
        ? location.logradouro.split(' ', 1)
        : '';
      const logradouro = await handleLogradouroInfo(siglaLogradouro[0]);
      setForm({
        ...form,
        cep: location.cep,
        endereco: location.logradouro
          ? location.logradouro.split(' ').slice(1).join(' ')
          : '',
        bairro: location.bairro,
        uf: location.uf,
        ...city,
        ...logradouro,
      });
    }
  }

  async function handleCityInfo({ localidade, uf }) {
    const params = {
      skip: 0,
      take: 1,
      filter: ['nomeCidade', '=', localidade],
    };
    let cityInfo = {};
    const data = await handleLoadCity(params, uf).then(({ data }) => data);

    cityInfo = {
      idCidade: data[0].idCidade,
      nomeCidade: data[0].nomeCidade,
      idUf: data[0].idUf,
      uf: data[0].nomeSigla,
    };

    return cityInfo;
  }

  async function handleLogradouroInfo(logradCorreios) {
    const params = {
      skip: 0,
      take: 1,
      filter: ['descricao', '=', logradCorreios],
    };
    let logradouroInfo = {};
    const data = await handleLoadLogradouro(params, logradCorreios).then(
      ({ data }) => data
    );

    logradouroInfo = {
      idLogradouro: data[0].id,
      descricaoLogradouro: data[0].descricao,
    };

    return logradouroInfo;
  }

  return (
    <>
      <FlexboxGrid align="top" style={{ marginTop: '16px' }}>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={3}>
          <MaskInput
            label="CEP"
            mask={{ blocks: [5, 3], delimiter: '-', numericOnly: true }}
            value={form.cep}
            style={{ margin: 8 }}
            margin="0"
            width="100%"
            placeholder="Insira o CEP"
            onChange={handleCep}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={4}>
          <SelectPicker
            dataSource={gridDataSourceLogradouro}
            label="Logradouro"
            displayExpr="descricao"
            valueExpr="idLogradouro"
            value={form.descricaoLogradouro}
            editValue={form.descricaoLogradouro}
            colums={[{ dataField: 'descricao', caption: 'Logradouro' }]}
            onChange={(event) => {
              setForm({
                ...form,
                descricaoLogradouro: event.logradouro,
                idLogradouro: event.id,
              });
            }}
            placeholder="Selecione o Logradouro"
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={7}>
          <TextField
            width="100%"
            label="Endereco"
            name="endereco"
            placeholder="Insira o Endereco"
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={3}>
          <TextField
            width="100%"
            label="Número"
            name="numero"
            placeholder="Insira o Número"
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={7}>
          <TextField
            width="100%"
            label="Bairro"
            name="bairro"
            placeholder="Insira o Bairro"
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
      {/* Lower */}
      <FlexboxGrid align="top" style={{ marginTop: '16px' }}>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={4}>
          <SelectPicker
            dataSource={gridDataSourceState}
            label="Estado"
            displayExpr="nome"
            valueExpr="idUf"
            value={form.uf}
            editValue={form.uf}
            colums={[
              { dataField: 'sigla', caption: 'UF' },
              { dataField: 'nome', caption: 'Estado' },
            ]}
            onChange={(event) => {
              setForm({
                ...form,
                idUf: event.id,
                uf: event.sigla,
              });
            }}
            placeholder="Selecione o Estado"
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
          <SelectPicker
            dataSource={gridDataSourceCity}
            disabled={!form.uf}
            label="Cidade"
            displayExpr="nomeCidade"
            valueExpr="idCidade"
            value={form.idCidade}
            load={handleLoadCity}
            editValue={form.nomeCidade}
            colums={[
              { dataField: 'nomeCidade', caption: 'Cidade' },
              { dataField: 'nomeSigla', caption: 'Estado' },
            ]}
            onChange={(event) => {
              setForm({
                ...form,
                idCidade: event.idCidade,
                nomeCidade: event.nomeCidade,
              });
            }}
            placeholder="Selecione a cidade"
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={4}>
          <MaskInput
            label="Telefone"
            mask={{
              numericOnly: true,
              phone: true,
              phoneRegionCode: 'BR',
            }}
            value={form?.telefone}
            placeholder="Insira o telefone"
            width="100%"
            margin="0"
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={10}>
          <TextField
            width="100%"
            label="Email"
            name="email"
            placeholder="Insira o Email"
            type="email"
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
}
