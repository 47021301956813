import { useEffect } from "react";
import { Col, FlexboxGrid, Checkbox, CheckboxGroup } from "rsuite";
import axios from "axios";
import CustomStore from "devextreme/data/custom_store";

import removerAcentos from "../../../services/helpers";

import TextField from "../../../components/form/textField/TextField";
import MaskInput from "../../../components/form/maskInput/MaskInput";
import SelectPicker from "../../../components/selectPicker/SelectPicker";
import TextFieldCurrency from "../../../components/textFieldCurrency/TextFieldCurrency";

import {
  handleLoadCity,
  gridDataSourceUf,
  handleLoadLogradouro,
  gridDataSourceNacionalidade,
  gridDataSourceEscolaridade,
  gridDataSourceResidencia,
} from "../BeneficiarioService";

export default function EnderecoPessoa({ form, setForm, errors, setErrors }) {
  const gridDataSourceCity = {
    store: new CustomStore({
      key: "idCidade",
      byKey: (loadOptions) => handleLoadCity(loadOptions, form.uf),
      load: (loadOptions) => handleLoadCity(loadOptions, form.uf),
    }),
  };

  const gridDataSourceLogradouro = {
    store: new CustomStore({
      key: "id",
      byKey: (loadOptions) => handleLoadLogradouro(loadOptions),
      load: (loadOptions) => handleLoadLogradouro(loadOptions),
    }),
  };

  async function handleCep(value) {
    if (value.length < 9) {
      setForm({
        ...form,
        cep: "",
        descricaoLogradouro: "",
        endereco: "",
        siglaLogradouro: "",
        complemento: "",
        bairro: "",
        uf: "",
        idLogradouro: null,
        idUf: null,
        idCidade: null,
      });
      return;
    }
    const location = await axios
      .get(`https://viacep.com.br/ws/${value}/json`)
      .then((res) => res.data);
    if (location?.cep) {
      const city = await handleCityInfo(location);
      const siglaLogradouro = location.logradouro.split(" ", 1);
      const logradouro = await handleLogradouroInfo(siglaLogradouro[0]);
      setForm({
        ...form,
        cep: location.cep,
        endereco: location.logradouro
          ? location.logradouro.split(" ").slice(1).join(" ")
          : "",
        siglaLogradouro: location.logradouro
          ? location.logradouro.split(" ", 1)
          : "",
        complemento: location.complemento ? location.complemento : "",
        bairro: location.bairro ? location.bairro.substring(0, 50) : "",
        uf: location.uf ? location.uf : "",
        ...city,
        ...logradouro,
      });
      setErrors({ ...errors, cep: null });
    } else {
      setErrors({ ...errors, cep: "CEP inválido" });
    }
  }

  async function handleCityInfo({ localidade, uf }) {
    const params = {
      sort: null,
      group: null,
      requireTotalCount: true,
      searchOperation: "contains",
      searchValue: null,
      userData: {},
      skip: 0,
      take: 1,
      filter: ["nomeCidade", "=", localidade],
    };
    let cityInfo = {};
    const data = await handleLoadCity(params, uf).then(({ data }) => data);

    cityInfo = {
      idCidade: data[0].idCidade,
      nomeCidade: data[0].nomeCidade,
      idUf: data[0].idUf,
      uf: data[0].nomeSigla,
    };

    return cityInfo;
  }

  async function handleLogradouroInfo(logradCorreios) {
    const params = {
      skip: 0,
      take: 1,
      filter: ["descricao", "=", logradCorreios],
    };
    let logradouroInfo = {};
    const data = await handleLoadLogradouro(params, logradCorreios).then(
      ({ data }) => data
    );

    logradouroInfo = {
      idLogradouro: data[0].id,
      descricaoLogradouro: data[0].descricao,
    };

    return logradouroInfo;
  }

  async function handleInfoBeneficioGoverno(event) {
    setForm((form) => ({
      ...form,
      beneficioGovernoCheck:
        event.indexOf("beneficioGovernoCheck") !== -1 ? true : false,
    }));
  }
  return (
    <>
      <FlexboxGrid align="top" style={{ marginTop: "16px" }}>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={2}>
          <MaskInput
            placeholder="Digite um CEP"
            label="CEP*"
            name="cep"
            mask={{
              blocks: [5, 3],
              delimiters: ["-"],
              numericOnly: true,
            }}
            value={form.cep}
            onChange={handleCep}
            error={errors.cep}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
          <SelectPicker
            dataSource={gridDataSourceLogradouro}
            label="Logradouro*"
            displayExpr="descricao"
            valueExpr="idLogradouro"
            value={form.descricaoLogradouro}
            editValue={form.descricaoLogradouro}
            colums={[
              { dataField: "sigla", caption: "Sigla" },
              { dataField: "descricao", caption: "Logradouro" },
            ]}
            onChange={(event) => {
              setForm({
                ...form,
                descricaoLogradouro:
                  event.logradouro || form.descricaoLogradouro,
                idLogradouro: event.id || form.idLogradouro,
              });
            }}
            placeholder="Selecione o Logradouro"
            error={errors.idLogradouro}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={8}>
          <TextField
            width="100%"
            label="Endereço*"
            name="endereco"
            placeholder="Endereço"
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item componentClass={Col} colspan={24} md={2}>
          <TextField
            width="100%"
            label="Número*"
            name="numero"
            placeholder="Número"
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={3}>
          <TextField
            width="100%"
            label="Complemento"
            name="complemento"
            placeholder="Complemento"
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item componentClass={Col} colspan={24} md={3}>
          <TextField
            width="100%"
            label="Bairro*"
            name="bairro"
            placeholder="Bairro"
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <FlexboxGrid align="top" style={{ marginTop: "16px" }}>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={4}>
          <SelectPicker
            dataSource={gridDataSourceUf}
            label="Estado*"
            displayExpr="sigla"
            valueExpr="idUf"
            value={form.idUf}
            editValue={form.uf}
            colums={[
              { dataField: "sigla", caption: "Sigla" },
              { dataField: "nome", caption: "Nome" },
            ]}
            onChange={(event) => {
              setForm({
                ...form,
                nomeCidade: "",
                idCidade: null,
                idUf: event.id || form.idUf,
                uf: event.sigla || form.uf,
              });
            }}
            placeholder="Selecione o Estado"
            error={errors.idUf}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
          <SelectPicker
            dataSource={gridDataSourceCity}
            disabled={!form.uf}
            label="Cidade*"
            displayExpr="nomeCidade"
            valueExpr="idCidade"
            value={form.idCidade}
            load={handleLoadCity}
            editValue={form.nomeCidade}
            colums={[
              { dataField: "nomeCidade", caption: "Cidade" },
              { dataField: "nomeSigla", caption: "Estado" },
            ]}
            onChange={(event) => {
              setForm({
                ...form,
                idCidade: event.idCidade || form.idCidade,
                nomeCidade: event.nomeCidade || form.nomeCidade,
              });
            }}
            placeholder="Selecione a cidade"
            error={errors.idCidade}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={4}>
          <MaskInput
            label="Telefone"
            mask={{
              numericOnly: true,
              phone: true,
              phoneRegionCode: "BR",
            }}
            value={form.telefone}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={10}>
          <TextField
            width="100%"
            label="E-mail"
            name="email"
            placeholder="Digite um E-mail"
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <FlexboxGrid align="top" style={{ marginTop: "16px" }}>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={3}>
          <MaskInput
            label="Celular"
            mask={{
              numericOnly: true,
              phone: true,
              phoneRegionCode: "BR",
            }}
            value={form.celular}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={3}>
          <MaskInput
            label="Telefone Recado"
            mask={{
              numericOnly: true,
              phone: true,
              phoneRegionCode: "BR",
            }}
            value={form.telefoneRecado}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={3}>
          <SelectPicker
            dataSource={gridDataSourceNacionalidade}
            label="Nacionalidade*"
            displayExpr="descricao"
            valueExpr="id"
            value={form.descricaoNacionalidade}
            editValue={form.descricaoNacionalidade}
            colums={[{ dataField: "descricao", caption: "Nacionalidade" }]}
            onChange={(event) => {
              setForm({
                ...form,
                descricaoNacionalidade:
                  event.descricao || form.descricaoNacionalidade,
                idNacionalidade: event.id || form.idNacionalidade,
              });
            }}
            placeholder="Selecione o Nacionalidade"
            error={errors.idNacionalidade}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item componentClass={Col} colspan={24} md={4}>
          <SelectPicker
            dataSource={gridDataSourceEscolaridade}
            label="Escolaridade*"
            displayExpr="descricao"
            valueExpr="id"
            value={form.descricaoEscolaridade}
            editValue={form.descricaoEscolaridade}
            colums={[{ dataField: "descricao", caption: "Escolaridade" }]}
            onChange={(event) => {
              setForm({
                ...form,
                descricaoEscolaridade:
                  event.descricao || form.descricaoEscolaridade,
                idEscolaridade: event.id || form.idEscolaridade,
              });
            }}
            placeholder="Selecione o Escolaridade"
            error={errors.idEscolaridade}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item componentClass={Col} colspan={24} md={3}>
          <SelectPicker
            dataSource={gridDataSourceResidencia}
            label="Tipo Residência*"
            displayExpr="descricao"
            valueExpr="id"
            value={form.descricaoResidencia}
            editValue={form.descricaoResidencia}
            colums={[{ dataField: "descricao", caption: "Tipo Residência" }]}
            onChange={(event) => {
              setForm({
                ...form,
                descricaoResidencia:
                  event.descricao || form.descricaoResidencia,
                idTipoResidencia: event.id || form.idTipoResidencia,
              });
            }}
            placeholder="Selecione o Logradouro"
            error={errors.idTipoResidencia}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item componentClass={Col} colspan={24} md={3}>
          <TextFieldCurrency
            name="rendaFamiliar"
            label="Renda Familiar*"
            value={form.rendaFamiliar}
            onChange={(e, value) => {
              setForm({ ...form, rendaFamiliar: value });
            }}
            error={errors.rendaFamiliar}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item
          componentClass={Col}
          colspan={24}
          md={4}
          style={{ marginTop: "15px" }}
        >
          <TextField
            margin="0"
            width="100%"
            name="beneficioGoverno"
            accepter={CheckboxGroup}
            onChange={handleInfoBeneficioGoverno}
          >
            <Checkbox value="beneficioGovernoCheck">Beneficio Governo</Checkbox>
          </TextField>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <FlexboxGrid align="top" style={{ marginTop: "16px" }}>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={15}>
          <TextField
            width="100%"
            label="Observação"
            name="observacoes"
            placeholder="Observação"
            style={{ maxWidth: "100%" }}
            textarea
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
}
