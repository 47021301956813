import React from "react";
import { FormGroup, ControlLabel, Whisper, Tooltip } from "rsuite";

const InputModal = (props) => {
  const {
    label,
    descricao,
    onClick,
    placeholder,
    margin,
    error,
    readOnly,
  } = props;
  return (
    <>
      <div style={{ margin: margin ?? 8 }}>
        <FormGroup
          className="rs-form-control-wrapper"
          style={{ width: props?.width }}
        >
          <ControlLabel> {label} </ControlLabel>
          <Whisper
            open={error ? true : false}
            trigger={"none"}
            placement={"bottomStart"}
            speaker={
              <Tooltip visible className="tooltip">
                {error}
              </Tooltip>
            }
          >
            <div
              className={`rs-input  input-modal `}
              style={{
                width: props?.width,
                cursor: readOnly ? "default" : "pointer",
              }}
              onClick={readOnly ? null : onClick}
            >
              {descricao ? descricao : placeholder}
            </div>
          </Whisper>
        </FormGroup>
      </div>
    </>
  );
};

export default InputModal;
