import React, { useState } from 'react';
import { FormGroup, Button, Form } from 'rsuite';
import { useHistory } from 'react-router-dom';

import logo from '../../assets/images/Logo_oficial.png';
import LoginForm from './LoginForm';
import { login } from './LoginService';
import CardLogin from '../../components/cardLogin/CardLogin';

import './Login.css';

const Login = () => {
  const history = useHistory();

  const [load, setLoad] = useState(false);
  const [user, setUser] = useState({
    value: '',
    blur: false,
  });
  const [password, setPassword] = useState({
    value: '',
    blur: false,
  });
  const [error, setError] = useState('');

  const handleSubmit = () => {
    setUser({ ...user, blur: true });
    setPassword({ ...password, blur: true });

    if (user.value.length > 0 && password.value.length > 0) {
      setLoad(true);
      login(
        user.value,
        password.value,
        (message) => {
          setError(message);
          setLoad(false);
        },
        () => {
          setLoad(false);
          sessionStorage.setItem('login', true);
          history.push('/');
        }
      );
    }
  };

  return (
    <div className="loginPage">
      <CardLogin>
        <div className="loginContainer">
          <img src={logo} alt="Orgsystem logo" className="loginImage" />
          <p>Informe seu usuário e senha para acessar</p>
          <Form>
            <LoginForm
              user={user}
              setUser={setUser}
              password={password}
              setPassword={setPassword}
            />

            <FormGroup className="loginButtonGroup">
              <Button
                color="blue"
                className="loginButton"
                type="submit"
                loading={load}
                onClick={() => handleSubmit()}
              >
                ENTRAR
              </Button>
            </FormGroup>
            <p className="loginError">{error && error}</p>
          </Form>
        </div>
      </CardLogin>
    </div>
  );
};

export default Login;
