import React, { useState } from "react";
import { DataGrid } from "devextreme-react";
import {
  Button,
  Column,
  Editing,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import { dataSourceHistorico } from "../AtendimentoService";
export default function HistoricoGrid() {
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };

  return (
    <>
      <DataGrid
        dataSource={dataSourceHistorico}
        remoteOperations={true}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        style={{ marginTop: 20 }}
      >
        <Editing mode="row" useIcons />
        <Pager
          allowedPageSizes={[7, 15, 30]}
          visible={true}
          showPageSizeSelector={true}
          showInfo={true}
        />
        <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
        <HeaderFilter />
        <FilterRow
          visible={filterOptions.showFilterRow}
          applyFilter={filterOptions.currentFilter}
        />
        <Column
          dataField="dataInclusao"
          allowEditing={false}
          alignment="left"
          dataType="datetime"
        >
          <HeaderFilter groupInterval={70} />
        </Column>

        <Column
          dataField="descricaoBeneficio"
          allowEditing={false}
          alignment="left"
        >
          <HeaderFilter groupInterval={70} />
        </Column>

        <Column dataField="nomePastoral" allowEditing={false}>
          <HeaderFilter groupInterval={70} />
        </Column>

        <Column dataField="quantidade" allowEditing={false}>
          <HeaderFilter groupInterval={70} />
        </Column>

        <Column
          dataField="valor"
          allowEditing={false}
          calculateCellValue={(rowData) => {
            return (
              `R$ ${rowData?.valor?.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              })}` ?? "R$ 0,00"
            );
          }}
        >
          <HeaderFilter groupInterval={70} />
        </Column>
      </DataGrid>
    </>
  );
}
