import React, { useState, useEffect } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Selection,
  Paging,
} from "devextreme-react/data-grid";
import { Modal, Loader, SelectPicker } from "rsuite";
import { getDados } from "./RelatorioService";

const DynamicModal = ({
  toggleModal,
  visible,
  height = "100%",
  idSql,
  nameStorage,
  filter,
  setFilter,
  listVariables,
  composicaoGrid,
  inputs,
  setInputs,
  inputSelected,
  setInputSelected,
  idRelatorio,
}) => {
  const [data, setData] = useState([]);
  const [colums, setColums] = useState([]);
  const [nomeId, setNomeId] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (visible) {
      getDados(idSql, listVariables, idRelatorio)
        .then((res) => {
          setColums(res.nomeColunas);
          setNomeId(res.nomeColunas[0]);
          handleCheckedIds(res.data, res.nomeColunas[0]);
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }
  }, [visible]);

  const checkToggle = () => {
    toggleModal();
  };

  const handleSelectionChanged = (e) => {
    e.component.getSelectedRowsData().then((items) => {
      let arrayIds = [];
      items.map((item) => {
        arrayIds = [...arrayIds, item[nomeId]];
      });

      arrayIds.length
        ? localStorage.setItem(nameStorage, JSON.stringify(arrayIds))
        : localStorage.removeItem(nameStorage);
      setFilter({
        ...filter,
        [inputSelected?.conteudo]: JSON.stringify(arrayIds),
      });
    });
  };

  const handleCheckedIds = (dados, id) => {
    let checkedIds = localStorage.getItem(nameStorage);

    checkedIds &&
      dados.map((item) => {
        if (JSON.parse(checkedIds).includes(item[id])) {
          item.CHECK = true;
        }
      });
    setData(dados);
  };

  function handleChangeComposicao(value, data) {
    inputs.map((item) => {
      if (inputSelected?.descricao === item.descricao) {
        item.valueComposicaogrid = value;
        setInputSelected({ ...item });
      }
    });
    setInputs([...inputs]);
  }
  return (
    <Modal
      show={visible}
      onHide={checkToggle}
      style={{
        minWidth: "40%",
        height,
        width: "1200px",
        maxWidth: "100%",
      }}
    >
      <Modal.Header style={{ display: "flex" }}>
        <Modal.Title>Selecione {inputSelected?.descricao} </Modal.Title>

        <SelectPicker
          data={composicaoGrid}
          id="valueInputComposicao"
          appearance="default"
          defaultValue={
            inputSelected?.valueComposicaogrid
              ? inputSelected?.valueComposicaogrid
              : composicaoGrid[0]?.operador
          }
          style={{ width: 300, marginRight: 50 }}
          labelKey="descricao"
          valueKey="operador"
          searchable={false}
          onSelect={handleChangeComposicao}
        />
      </Modal.Header>
      <Modal.Body style={{ overFlowY: "auto" }}>
        {loading ? (
          <div className="center">
            <Loader></Loader>
          </div>
        ) : (
          <DataGrid
            dataSource={data}
            remoteOperations={true}
            showBorders={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            onSelectionChanged={handleSelectionChanged}
            defaultSelectionFilter={["CHECK", "=", true]}
            keyExpr={[nomeId]}
          >
            <Selection
              mode="multiple"
              selectAllMode={"allPages"}
              showCheckBoxesMode={"onClick"}
              deferred={true}
            />

            <FilterRow visible={true} applyFilter={true} />
            <HeaderFilter visible={true} />
            {colums.map(
              (item, i) =>
                i !== 0 &&
                item !== "CHECK" && (
                  <Column key={item} dataField={item}>
                    <HeaderFilter allowSearch={true} />
                  </Column>
                )
            )}
          </DataGrid>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DynamicModal;
