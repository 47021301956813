import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, FlexboxGrid, Form, Schema } from 'rsuite';
import { addIdBeneficio } from '../../store/modules/beneficio/action';
import { handleCreateBeneficio } from './BeneficioService';
import Descricao from './Form/Descricao';
import PastoralModalGrid from './Grids/PastoralModalGrid';

export default function BeneficioForm({
  form,
  setForm,
  initialForm,
  successMessage,
}) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  function handleFormChange(value) {
    setForm({ ...form, ...value });
  }

  let formValid = useRef();

  async function handleSubmit() {
    if (formValid.check()) {
      await handleCreateBeneficio(form).then((res) => {
        const { id } = res;
        if (!id) return;
        dispatch(addIdBeneficio(id));
        setForm({ ...form, id });
      });
    }
  }
  const { StringType } = Schema.Types;

  const model = Schema.Model({
    descricao: StringType().isRequired('Descrição é requirido'),
  });

  return (
    <Form
      formValue={form}
      onChange={handleFormChange}
      formError={errors}
      onCheck={setErrors}
      ref={(ref) => (formValid = ref)}
      onSubmit={handleSubmit}
      model={model}
      fluid
      style={{ width: '100%' }}
    >
      <Descricao form={form} setForm={setForm} />

      {form.id ? (
        <FlexboxGrid
          className="marginTop"
          justify="end"
          style={{ margin: '16px 0' }}
        >
          <Button
            type="button"
            appearance="ghost"
            color="blue"
            onClick={() => {
              setForm(initialForm);
              dispatch(addIdBeneficio(null));
            }}
            style={{ marginRight: '16px' }}
          >
            Incluir Novo
          </Button>
          <Button
            type="button"
            appearance="primary"
            color="green"
            onClick={(e) => handleSubmit(e)}
          >
            Alterar
          </Button>
        </FlexboxGrid>
      ) : (
        <FlexboxGrid
          className="marginTop"
          justify="end"
          style={{ marginTop: '16px' }}
        >
          <Button
            type="button"
            appearance="primary"
            color="green"
            onClick={(e) => handleSubmit(e)}
          >
            Salvar
          </Button>
        </FlexboxGrid>
      )}

      {form.id ? (
        <PastoralModalGrid form={form} successMessage={successMessage} />
      ) : (
        ''
      )}
    </Form>
  );
}
