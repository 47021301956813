import axios from "axios";

import { Auth } from "../config/Storage";

const apiReport = axios.create({
  headers: {
    accept: "*/*",
    "Content-Type": "application/json-patch+json",
    "Access-Control-Allow-Origin": "*",
  },
  baseURL: process.env.REACT_APP_API_RELATORIOS,
});

apiReport.interceptors.request.use((config) => {
  const token = sessionStorage.getItem(Auth)
    ? JSON.parse(sessionStorage.getItem(Auth)).accessToken
    : "";

  const headers = { ...config.headers };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return { ...config, headers };
});

export default apiReport;
