export default function Atendimento(state = {}, action) {
  switch (action.type) {
    case "ADD_ID_ATENDIMENTO": {
      return {
        ...state,
        idAtendimento: action.idAtendimento,
      };
    }
    case "ADD_ID_BENEFICIARIO": {
      return {
        ...state,
        idBeneficiario: action.idBeneficiario,
      };
    }
    default:
      return state;
  }
}
