import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormGroup, ControlLabel, Tooltip, Whisper, Loader } from "rsuite";
import DataGrid, {
  Selection,
  Paging,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid";
import DropDownBox from "devextreme-react/drop-down-box";
import { getDadosDropdown } from "../../../pages/relatorio/RelatorioService";

const DropdownReport = (props) => {
  const { params, margin, width } = props;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [valueParam, setValueParam] = useState("");
  const [nameColumn, setNameColumn] = useState("");
  const [load, setLoad] = useState(null);

  function handleRequest(e) {
    let listaVariaveis = props.returnListVariable(params.variaveisTroca);
    if (!data.length) {
      setLoad(true);
      getDadosDropdown(params.idSqlParametro, listaVariaveis, props.idRelatorio)
        .then((res) => {
          setData(res.data);
          let newColumns = [];
          res.nomeColunas.map((item, i) => {
            if (i !== 0) {
              newColumns.push(item);
            }
          });
          setColumns(newColumns);
          setValueParam(res.nomeColunas[0]);
          setNameColumn(res.nomeColunas[1]);
        })
        .finally(() => setLoad(false));
    }
  }
  function dataGridRender(gridColumns) {
    return load === false ? (
      <DataGrid
        className="gridHover"
        dataSource={data}
        columns={columns}
        onRowClick={(e) => {
          props.handleChange(e.data[valueParam], e.data[nameColumn]);
          setOpen(false);
        }}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    ) : (
      <div className="center" style={{ margin: 10 }}>
        <Loader size="sm" />
      </div>
    );
  }

  return (
    <>
      <div style={{ margin: margin ?? 8, width: width ?? 300 }}>
        <FormGroup>
          <ControlLabel> {params.descricao} </ControlLabel>
          <Whisper
            open={props.error ? true : false}
            trigger="none"
            placement={"bottomStart"}
            speaker={<Tooltip className="tooltip">{props.error}</Tooltip>}
          >
            <DropDownBox
              value={params.descricaoValor}
              opened={open}
              deferRendering={false}
              placeholder={`Selecione ${params.descricao}`}
              showClearButton={true}
              onFocusIn={(e) => handleRequest(e)}
              onValueChanged={(e) => {
                setOpen(false);
                props.handleClearDropdown();
              }}
              onOptionChanged={(e) => {
                if (e.name === "opened") {
                  setOpen(e.value);
                }
              }}
              contentRender={dataGridRender}
            />
          </Whisper>
        </FormGroup>
      </div>
    </>
  );
};

DropdownReport.propTypes = {
  accepter: PropTypes.any,
  error: PropTypes.any,
  label: PropTypes.any,
  name: PropTypes.any,
};

export default DropdownReport;
