import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";

import Routes from "./routes";
import Store from "./store/Store";
import "./components/form/Form.css";

import "./App.css";

function App() {
  const initialTheme = localStorage.getItem("theme") || "theme_blue";
  const [theme, setTheme] = useState(initialTheme);

  return (
    <Provider store={Store}>
      <div className={`appContainer ${theme}`}>
        <ToastProvider placement="bottom-center">
          <BrowserRouter>
            <Routes setTheme={setTheme} />
          </BrowserRouter>
        </ToastProvider>
      </div>
    </Provider>
  );
}

export default App;
