import { useEffect, useState } from "react";
import {
  Checkbox,
  CheckboxGroup,
  Col,
  FlexboxGrid,
  InputPicker,
  SelectPicker as SelectPickerRsuit,
} from "rsuite";
import TextField from "../../../components/form/textField/TextField";
import SelectInput from "../../../components/form/selectInput/SelectInput";
import MaskInput from "../../../components/form/maskInput/MaskInput";
import DateInputEditable from "../../../components/form/dateInputEditable/DateInputEditable";
import { handleGetCpf, handleLoadEstadoCivil } from "../BeneficiarioService";

export default function InfoPessoas({ form, setForm, errors, setErrors }) {
  const [estadoCivil, setEstadoCivil] = useState([]);

  useEffect(() => {
    async function handleEstadoCivil() {
      const response = await handleLoadEstadoCivil();
      setEstadoCivil(response.data);
    }

    handleEstadoCivil();
  }, []);

  async function handleInfoEmpregado(event) {
    setForm((form) => ({
      ...form,
      empregadoCheck: event.indexOf("empregadoCheck") !== -1 ? true : false,
    }));
  }

  async function handleCpf(e) {
    const response = await handleGetCpf(e);
    setForm({ ...form, cpf: e });
    if (response) {
      setErrors({ ...errors, cpf: "CPF já existe" });
    }
  }
  return (
    <>
      <FlexboxGrid align="top" style={{ marginTop: "16px" }}>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={8}>
          <TextField
            width="100%"
            label="Nome*"
            name="nome"
            placeholder="Nome"
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={4}>
          <MaskInput
            placeholder="Digite um CPF"
            label="CPF*"
            name="cpf"
            mask={{
              blocks: [3, 3, 3, 2],
              delimiters: [" ", " ", " "],
              numericOnly: true,
            }}
            value={form.cpf}
            onChange={(e) => handleCpf(e)}
            error={errors.cpf}
            // style={{ margin: 8 }}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={4}>
          <MaskInput
            placeholder="Digite um RG"
            label="RG"
            mask={{
              blocks: [2, 3, 3, 1],
              delimiters: [" ", " ", " "],
              numericOnly: true,
            }}
            value={form.rg}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={4}>
          <DateInputEditable
            state="nascimento"
            width="100%"
            label="Nascimento*"
            setForm={setForm}
            value={form.nascimento}
            margin="0"
            error={errors.nascimento}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={4}>
          <TextField
            margin="0"
            width="100%"
            name="sexo"
            label="Sexo*"
            accepter={InputPicker}
            valueKey="value"
            labelKey="name"
            data={[
              { name: "Masculino", value: "M" },
              { name: "Feminino", value: "F" },
            ]}
            error={errors.sexo}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <FlexboxGrid align="top" style={{ marginTop: "16px" }}>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={3}>
          <TextField
            valueKey="id"
            data={estadoCivil}
            labelKey="descricao"
            label="Estado Civil*"
            name="idEstadoCivil"
            accepter={InputPicker}
            className="selectPicker"
            error={errors.idEstadoCivil}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item componentClass={Col} colspan={24} md={7}>
          <TextField
            width="100%"
            label="Profissão"
            name="profissao"
            placeholder="Profissão"
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item
          componentClass={Col}
          colspan={24}
          md={3}
          style={{ marginTop: "15px" }}
        >
          <TextField
            margin="0"
            width="100%"
            name="empregado"
            accepter={CheckboxGroup}
            onChange={handleInfoEmpregado}
          >
            <Checkbox value="empregadoCheck">Empregado*</Checkbox>
          </TextField>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={4}>
          <DateInputEditable
            state="dataInclusao"
            width="100%"
            label="Data Inclusão*"
            setForm={setForm}
            value={form.dataInclusao}
            margin="0"
            disabled
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={7}>
          <TextField
            width="100%"
            label="Paróquia Inclusão"
            name="descricaoParoquiaInclusao"
            placeholder="Paróquia Inclusão"
            disabled
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </>
  );
}
