import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import FilterModal from '../../components/filter/FilterModal';
import '../Page.css';
import { PastoralFilter } from './PastoralFilter';
import PastoralForm from './PastoralForm';

const initialForm = {
  cep: '',
  bairro: '',
  endereco: '',
  cidade: '',
  estado: '',
  cnpj: '',
  nome: '',
  descricaoLogradouro: '',
  numero: '',
  email: '',
  telefone: '',
  site: '',
  coordenador: '',
  observacoes: '',
  uf: '',
  nomeCidade: '',
};

export const Pastoral = () => {
  const { addToast } = useToasts();
  const [toggle, setToggle] = useState(false);
  const [form, setForm] = useState(initialForm);
  function successMessage(message) {
    addToast(message, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  function warningMessage(message) {
    addToast(message, {
      appearance: 'warning',
      autoDismiss: true,
    });
  }

  function errorMessage(message) {
    addToast(message, {
      appearance: 'error',
      autoDismiss: false,
    });
  }

  return (
    <div className="pageContainer">
      <div className="pageFlex">
        <div className="pageContent withoutSearch">
          <h4>Pastoral</h4>

          <PastoralForm
            form={form}
            setForm={setForm}
            errorMessage={errorMessage}
            successMessage={successMessage}
            warningMessage={warningMessage}
            initialForm={initialForm}
          />
        </div>
        <FilterModal toggle={toggle} setToggle={setToggle} full>
          <PastoralFilter
            form={form}
            setForm={setForm}
            setToggle={setToggle}
            errorMessage={errorMessage}
            successMessage={successMessage}
            initialForm={initialForm}
          />
        </FilterModal>
      </div>
    </div>
  );
};
