import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Login from "./pages/login/Login";
import Sidebar from "./components/sidebar/Sidebar";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { Auth } from "./config/Storage";
import Pages from "./authorization/pages";
import RouteAuthorization from "./authorization/routeAuthorization";
import { loginByDashboard } from "./services/helpers";
import { Loader } from "rsuite";
import { useDispatch } from "react-redux";
import ModalCompany from "./components/modalCompany/ModalCompany";

const DashboardAuthorization = async (location, setLoading, dispatch) => {
  let loc = location.search.substring(1, location.search.length);
  let token = null;
  let param_name = false;
  let params = loc.split("&");
  for (let i = 0; i < params.length; i++) {
    param_name = params[i].substring(0, params[i].indexOf("="));
    if (param_name == "dG9rZW5DcmlwdG9ncmFmYWRv") {
      token = params[i].substring(params[i].indexOf("=") + 1);
    }
  }
  token = token?.replaceAll("%3D", "=");

  if (token) {
    let tokenDecrypt = atob(atob(token));
    window.history.replaceState(null, null, window.location.pathname);
    await loginByDashboard(tokenDecrypt, setLoading, dispatch);
  } else {
    setLoading(false);
  }
};

const PrivateRoute = ({ component: Component, setTheme, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      sessionStorage.getItem(Auth) != null ? (
        <div style={{ display: "flex", flexWrap: "nowrap" }}>
          <ModalCompany />

          <Sidebar {...props} />

          <div className="routerContainer">
            <Header setTheme={setTheme} />
            <Component {...props} />
            <Footer />
          </div>
        </div>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const GuestRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !sessionStorage.getItem(Auth) ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = (props) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    document.referrer.indexOf("https://orgdashboard.azurewebsites.net") !== -1
      ? DashboardAuthorization(window.location, setLoading, dispatch)
      : setLoading(false);
  }, []);
  return !loading ? (
    <>
      <Switch>
        <GuestRoute path="/login" component={Login} />
        {Pages.map((page) => {
          return (
            <PrivateRoute
              exact
              key={page.route}
              path={page.route}
              component={RouteAuthorization(page.component, page.name)}
              setTheme={props.setTheme}
            />
          );
        })}
      </Switch>
    </>
  ) : (
    <div className="overlay">
      <Loader backdrop vertical size="lg" />
    </div>
  );
};

export default Routes;
