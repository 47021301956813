import React from "react";

const Viewer = ({ token, id, number, activeNav }) => {
  const route =
    !!token &&
    !!id &&
    `${process.env.REACT_APP_API_RELATORIOS_VIEWER}Viewer?idLog=${id}&access_token=${token}`;

  return (
    <>
      {route ? (
        <iframe
          height="100%"
          width="95%"
          src={route}
          frameborder="0"
          style={{
            paddingTop: 20,
            minHeight: "100vw",
            display: number === activeNav && activeNav !== 1 ? "flex" : "none",
          }}
          title="OrgRelatórios"
        ></iframe>
      ) : (
        <span
          style={{
            color: "red",
            display: number === activeNav && activeNav !== 1 ? "flex" : "none",
          }}
        >
          Algo deu errado atualize a página e tente novamente
        </span>
      )}
    </>
  );
};

export default Viewer;
