import React from 'react';
import ReactDOM from 'react-dom';

import { IntlProvider } from 'rsuite';
import { loadMessages, locale } from 'devextreme/localization';

import ptMessages from 'devextreme/localization/messages/pt.json';
import pt_BR from 'rsuite/lib/IntlProvider/locales/pt_BR';
import App from './App';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'rsuite/dist/styles/rsuite-default.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
require('dotenv').config();

loadMessages(ptMessages);
locale('pt');

ReactDOM.render(
  <IntlProvider locale={pt_BR}>
    <App />
  </IntlProvider>,
  document.getElementById('root')
);
