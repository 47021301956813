import {
  Button,
  Column,
  DataGrid,
  Editing,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import React, { useState } from "react";
import { BeneficiarioFilterStore } from "./BeneficiarioService";
import { postAndPut } from "./object";

export function BeneficiarioFilter({
  setForm,
  setToggle,
  dataSourceGridDependentes,
  setDataSourceGridDependentes,
  dataSourceGridHistorico,
  setDataSourceGridHistorico,
  setCpfCadastrado,
  setErrors,
  setErrorGrid,
}) {
  const [pageIndex, setPageIndex] = useState(null);
  const [pageSize, setPageSize] = useState(15);

  const applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const filterOptions = {
    showFilterRow: true,
    showHeaderFilter: true,
    currentFilter: applyFilterTypes[0].key,
  };

  function handleOptionChange(event) {
    if (event.fullName === "paging.pageSize" && event.value !== pageSize) {
      setPageSize(event.value);
      setPageIndex(0);
    }

    setPageIndex(null);
  }

  function handleEdit(e) {
    setErrors({});
    setToggle(true);
    const newForm = e.row.data;
    setCpfCadastrado(e.row.data.cpf);
    setErrorGrid(false);
    setForm({
      ...postAndPut(newForm),
      idBeneficiario: e.row.data.id,
      uf: e.row.data.descricaoUf,
      nomeCidade: e.row.data.descricaoCidade,
      descricaoLogradouro: e.row.data.descricaoLogradouro,
      id: e.row.data.id,
      empregado: e.row.data.empregado ? ["empregadoCheck"] : [],
      nascimento: newForm.dataNascimento,
      descricaoNacionalidade: newForm.descricaoNacionalidade,
      descricaoEscolaridade: newForm.descricaoEscolaridade,
      descricaoResidencia: newForm.descricaotipoResidencia,
      descricaoParoquiaInclusao: newForm.descricaoParoquiaInclusao,
      listDependenteGrid: newForm.listBeneficiarioDependentes,
      listHistoricoGrid: newForm.listHistoricoAtivacao,
    });
    setDataSourceGridDependentes([...newForm.listBeneficiarioDependentes]);
    setDataSourceGridHistorico([...newForm.listHistoricoAtivacao]);
  }

  return (
    <>
      <DataGrid
        dataSource={BeneficiarioFilterStore}
        remoteOperations={true}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        onOptionChanged={handleOptionChange}
      >
        <Editing mode="row" useIcons allowDeleting />
        <Pager
          allowedPageSizes={[7, 15, 30]}
          visible={true}
          showPageSizeSelector={true}
          showInfo={true}
        />
        <Paging defaultPageSize={pageSize} pageIndex={pageIndex} />
        <FilterRow
          visible={filterOptions.showFilterRow}
          applyFilter={filterOptions.currentFilter}
        />
        <HeaderFilter />

        <Column
          dataField="nome"
          filterOperations={false}
          allowEditing={false}
          alignment="left"
        >
          <HeaderFilter groupInterval={70} />
        </Column>

        <Column type="buttons" width={100}>
          <Button hint="Editar" visible icon="edit" onClick={handleEdit} />
          <Button name="delete" visible hint="Deletar" icon="trash" />
        </Column>
      </DataGrid>
    </>
  );
}
