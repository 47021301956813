import { handleGetSessionStorage } from '../../services/helpers';

export function postAndPut(data) {
  const { auth } = handleGetSessionStorage();
  return {
    id: data.id || null,
    idPessoaEmpresa: auth.empresa,
    descricao: data.descricao,
    dataInclusao: data.dataInclusao || null,
    usuario: auth.nomeUsuario,
    excluido: false,
  };
}
