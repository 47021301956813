/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { Company } from "../../config/Storage";
import * as CompanyActions from "../../store/modules/company/action";

import { Link } from "react-router-dom";
import * as FontAwesome from "react-icons/fa";
import "./Home.css";
import { Auth } from "../../config/Storage";

const CardItem = ({ item }) => {
  const tabs = useSelector((state) => state.Header.tabs);
  const [tabsOpen, setTabsOpen] = useState([]);
  const user = JSON.parse(sessionStorage.getItem(Auth))?.nomeUsuario;

  let login = sessionStorage.getItem("login");

  useEffect(() => {
    if (JSON.parse(login)) {
      sessionStorage.setItem("login", false);
      window.location.reload(true);
    }
  }, []);
  useEffect(() => {
    let storageTabs = localStorage.getItem(`${user} - tabs`, tabs);
    storageTabs = JSON.parse(storageTabs);
    setTabsOpen(storageTabs);
  }, [tabs]);

  useEffect(() => {
    tabs && setTabsOpen(tabs);
  }, [tabs]);

  function openTab() {
    let tabExists = false;
    let descricao = item.idRelatorio
      ? `Relatório - ` + item.descricao
      : item.descricao;
    tabsOpen?.map((value) => {
      if (descricao === value.tab.descricao) {
        tabExists = true;
      }
      return item;
    });
    if (!tabExists) {
      tabsOpen?.unshift({ tab: { ...item, descricao: descricao } });
    }

    if (tabsOpen) {
      localStorage.setItem(`${user} - tabs`, JSON.stringify([...tabsOpen]));
      localStorage.setItem("activeTab", JSON.stringify(descricao));
    }
  }
  const Icon = FontAwesome[item.nomeImagem];
  return (
    <div>
      <Link
        title={`Ir para ${item.descricao}`}
        onClick={openTab}
        to={{
          pathname: item.url,
          state: { idRelatorio: item.idRelatorio, nome: item.descricao },
          key: item.id,
        }}
        className="cardShortcut"
        id={item.id}
      >
        <div className="cardIcon">
          {item.nomeImagem ? (
            <Icon size="30" className="icon" />
          ) : (
            <FontAwesome.FaAlignLeft size="30" className="icon" />
          )}
        </div>
        <span>{item.descricao}</span>
      </Link>
    </div>
  );
};

const Home = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem(Auth))?.nomeUsuario;

  const menus = localStorage.getItem(`${user} - menus`)
    ? JSON.parse(localStorage.getItem(`${user} - menus`))
    : [];

  useEffect(() => {
    const company = sessionStorage.getItem(Company);
    const checkSessionStorage =
      /undefined/.test(company) || /null/.test(company);

    if (checkSessionStorage) {
      dispatch(CompanyActions.toggleModal(true));
    } else {
      const companyParsed = JSON.parse(company);
      dispatch(CompanyActions.addCompany(companyParsed));
    }
  }, [dispatch]);

  return (
    <div className="homeContainer">
      <div className="shortcutContainer">
        {menus.length ? (
          <h3 className="title">Últimas páginas visualizadas</h3>
        ) : null}

        <div className="shortcutCards">
          {menus.map((item, i) => (
            <CardItem item={item} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
